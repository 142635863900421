import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MQTTClient from '../../setupConfig.js';
import SideBar from './SideBar.js';
import Canvas from './Canvas.js';
import axios from 'axios';
import JoyStick from './joystick.js';
import ThemeProvider from '../Context/Context.js';
import toast, { Toaster } from 'react-hot-toast';
import {
  usePathDraw,
  useAIPathDraw,
  useRecordPath,
  useTheme,
} from '../Context/Context.js';

const Home = () => {
  const { theme, setTheme } = useTheme();
  const [tags, setTags] = useState({});
  const navigate = useNavigate();
  const [cameraZoom, setCameraZoom] = useState(1);
  const [opacity, setOpacity] = useState(1); // New state for opacity
  const [companies, setCompanies] = useState([]);
  const [joystickPosition, setJoystickPosition] = useState({ x: 0, y: 0 });
  const { ToRecord } = usePathDraw();
  const {
    pathRecord,
    setPathRecord,
    showStation,
    setShowStation,
    showpath,
    setShowpath,
    canvasWidth,
    setCanvasWidth,
    cameraOffset,
    setCameraOffset,
    selectedPath,
    setSelectedPath,
    stationRecord,
    setStationRecord,
    stationMarkedCoordinate,
    setStationMarkedCoordinate,
    AIPathSensitivity,
    setAIPathSensitivity,
    tracePath,
    setTracePath,
    startParkingSpaceRecord,
    traceStation,
    startCartParkingSpaceRecord,
    CreateZone,
    AprilTags,
  } = useRecordPath();
  const { AIpath } = useAIPathDraw();

  const handleRangeChange = (event) => {
    setCameraZoom(event.target.value / 100);
  };

  const handleOpacityChange = (event) => {
    setOpacity(event.target.value / 100);
  };

  const handleAIPathChange = (event) => {
    setAIPathSensitivity(Math.floor(event.target.value));
  };

  const handleJoystickMove = (stickStatus) => {
    setJoystickPosition({
      x: stickStatus.x,
      y: stickStatus.y,
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          'https://drobot-admin-v2-a2def93839bb.herokuapp.com/home',
          {
            withCredentials: true,
          }
        );

        if (response) {
          setCompanies(response.data);
        } else {
          console.error('Failed to fetch company data');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    let Joy3;

    const joystick = async () => {
      console.log('joystick working');

      const joy3Param = { title: 'joystick3' };

      // Clean up the previous joystick manually
      const existingJoystick = document.getElementById('joy3Div');
      if (existingJoystick) {
        existingJoystick.innerHTML = ''; // Remove old joystick elements
      }

      // Create new joystick instance
      Joy3 = new JoyStick('joy3Div', joy3Param, handleJoystickMove);
    };

    if (
      ToRecord ||
      tracePath ||
      startParkingSpaceRecord ||
      stationRecord ||
      traceStation ||
      startCartParkingSpaceRecord ||
      CreateZone ||
      AprilTags
    ) {
      joystick();
    }

    return () => {
      console.log('Cleaning up joystick');
      const existingJoystick = document.getElementById('joy3Div');
      if (existingJoystick) {
        existingJoystick.innerHTML = '';
      }
    };
  }, [
    ToRecord,
    tracePath,
    startParkingSpaceRecord,
    stationRecord,
    traceStation,
    startCartParkingSpaceRecord,
    CreateZone,
    AprilTags
  ]);
  const [prevState,setPrevState] = useState({});
  

  useEffect(() => {
    if (window.sessionStorage.getItem('companyData') !== null) {
      const mqttClient = new MQTTClient(setTags);
      return () => {
        mqttClient.client.disconnect();
      };
    }
  }, []);

  
  

  return (
    <div className="App font-Poppins overflow-hidden">
      <Toaster />
      <SideBar cameraZoom={cameraZoom} />
      <Canvas
        tags={tags}
        cameraZoom={cameraZoom}
        opacity={opacity}
        setCameraZoom={setCameraZoom}
        joystickPosition={joystickPosition}
      />
      <div
        className={`flex flex-row justify-center items-center z-50 -rotate-90 ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} rounded-full absolute right-1 ${
          ToRecord ||
          tracePath ||
          startParkingSpaceRecord ||
          stationRecord ||
          traceStation ||
          startCartParkingSpaceRecord ||
          CreateZone ||
          AprilTags
            ? ' bottom-52'
            : 'bottom-20'
        } py-[5px] px-2 gap-2`}
      >
        <span className="text-white text-sm font-bold rotate-90">-</span>
        <input
          type="range"
          id="cameraZoomControl"
          className={`w-[100px] border-none h-[4px] ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} accent-white`}
          min="20"
          max="100"
          onChange={(e) => handleRangeChange(e)}
        />
        <span className="text-white text-sm font-bold">+</span>
      </div>
      <div
        className={`flex justify-center items-center z-50 -rotate-90 ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} rounded-full absolute right-1 ${
          ToRecord ||
          tracePath ||
          startParkingSpaceRecord ||
          traceStation ||
          startCartParkingSpaceRecord ||
          CreateZone || 
          AprilTags
            ? ' bottom-96'
            : 'bottom-60'
        } py-[5px] px-2 gap-2`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect
            x="9"
            y="9"
            width="12"
            height="12"
            rx="2"
            strokeOpacity="0.5"
          ></rect>
          <rect x="3" y="3" width="12" height="12" rx="2"></rect>
        </svg>

        <input
          type="range"
          id="opacityControl"
          className={`w-[100px] border-none h-[4px] ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} accent-white`}
          min="20"
          max="100"
          defaultValue={100}
          onChange={(e) => handleOpacityChange(e)}
        />
      </div>
      {AIpath && (
        <div
        className={`flex justify-center items-center z-50 -rotate-90 ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} rounded-full absolute right-1 bottom-[35rem] py-[5px] px-2 gap-2`}
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" d="M13.417 7.00049L0.583659 7.00049C0.583659 8.70229 1.2597 10.3344 2.46306 11.5378C3.66641 12.7411 5.29852 13.4172 7.00033 13.4172C8.70213 13.4172 10.3342 12.7411 11.5376 11.5378C12.741 10.3344 13.417 8.70229 13.417 7.00049Z" fill="white"/>
<path d="M13.417 7.00049L0.583659 7.00049" stroke="white" stroke-width="1.16667" stroke-miterlimit="10"/>
<path d="M0.583659 7.00041C0.583659 10.5442 3.4565 13.4171 7.00033 13.4171C10.5442 13.4171 13.417 10.5442 13.417 7.00041C13.417 3.45658 10.5442 0.58374 7.00033 0.58374C3.4565 0.58374 0.583659 3.45658 0.583659 7.00041Z" stroke="white" stroke-width="1.16667" stroke-miterlimit="10" stroke-linecap="square"/>
</svg>

          <input
            type="range"
            id="aiwindowcontrol"
            className={`w-[100px] border-none h-[4px] ${theme === 'light' ? 'bg-[#015D81]' : 'bg-[#0C2F3A]'} accent-white`}
            min="1"
            max="5"
            step="1"
            value={AIPathSensitivity} // This will initialize the slider with the correct AIPathSensitivity value
            onChange={(e) => handleAIPathChange(e)}
          />
        </div>
      )}
      {(ToRecord ||
        tracePath ||
        startParkingSpaceRecord ||
        stationRecord ||
        traceStation ||
        startCartParkingSpaceRecord ||
        CreateZone ||
        AprilTags) && (
        <div
          id="joy3Div"
          callback={handleJoystickMove}
          className="right-2 bottom-0"
          style={{ width: 120, height: 120, position: 'fixed', opacity: 1 }}
        ></div>
      )}
      ;
    </div>
  );
};

export default Home;
