import { Client as PahoClient } from 'paho-mqtt';
import TagsList from './components/helper_functions/TagList.js';

class MQTTClient {
  constructor(setTags) {
    this.setTags = setTags;
    this.tagList = new TagsList();
    this.client = null;
    this.connect();
  }

  connect() {
    // Retrieve companyData dynamically to ensure latest data
    const companyData = JSON.parse(
      window.sessionStorage.getItem('companyData')
    );

    if (!companyData || !companyData.mqtt_cloud) {
      console.error(
        'MQTT connection failed: companyData is not available yet.'
      );
      return;
    }

    var clientID =
      JSON.parse(window.sessionStorage.getItem('data')).uid +
      Math.floor(Math.random() * 1100).toString(8);

    let host = companyData.mqtt_cloud.host;
    let port = companyData.mqtt_cloud.port;

    this.client = new PahoClient(host, Number(port), clientID);

    this.client.onConnectionLost = this.onConnectionLost.bind(this);
    this.client.onMessageArrived = this.onMessageArrived.bind(this);

    var details = {
      onSuccess: this.onConnect.bind(this),
      onFailure: this.onConnectFailure.bind(this),
      userName: companyData.mqtt_cloud.user_name,
      password: companyData.mqtt_cloud.password,
      useSSL: true,
      cleanSession: false,
    };

    this.client.connect(details);
  }

  onConnect() {
    const companyData = JSON.parse(
      window.sessionStorage.getItem('companyData')
    );
    if (!companyData || !companyData.mqtt_cloud) {
      console.error('MQTT subscription failed: companyData is not available.');
      return;
    }

    console.log('Connected to MQTT broker');
    this.client.subscribe(companyData.mqtt_cloud.topic, {
      onSuccess: () => {
        console.log('Subscribed to the topic successfully');
      },
      onFailure: (err) => {
        console.log('Failed to subscribe to the topic:', err);
      },
    });
  }

  onConnectFailure(err) {
    console.log('Failed to connect to MQTT broker:', err);
  }

  onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log('Connection lost:', responseObject.errorMessage);
    }
  }

  onMessageArrived(message) {
    try {
      const data = JSON.parse(message.payloadString);

      data.forEach((tag) => {
        if (!this.tagList.TagsList[tag.tagId]) {
          this.tagList.addTag(tag.tagId);
        }

        if (tag.data && tag.data.coordinates) {
          this.tagList.setTagInfo(tag.tagId, 'coordinates', [
            tag.data.coordinates.x,
            tag.data.coordinates.y,
          ]);
        }
      });

      this.setTags(this.tagList.TagsList);
    } catch (error) {
      console.error(
        'Error processing message:',
        error,
        'Message:',
        message.payloadString
      );
    }
  }
}

export default MQTTClient;
