import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter } from "react-icons/fa";
import "react-loading-skeleton/dist/skeleton.css";

const BotStatus = () => {
  const navigate = useNavigate();
  const [botData, setBotData] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date().toLocaleString());
  const [loading, setLoading] = useState(true);

  const getBotData = async () => {
    const companyname = window.sessionStorage.getItem("companyName");
    if (!companyname) {
      toast.error("Please select a company first");
      navigate("/companies");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `https://drobot-admin-v2-a2def93839bb.herokuapp.com/${companyname}/botStatus`
      );
      setBotData(response.data);
      setLastUpdate(new Date().toLocaleString());
    } catch (error) {
      console.error("Error fetching bot data:", error);
    } finally {
      setLoading(false);
    }
  };
  const getBatteryIcon = (level) => {
    if (!level || isNaN(level)) return <FaBatteryQuarter className="text-gray-500" />; // Default if missing
    if (level > 50) return <FaBatteryFull className="text-green-500" />;
    if (level > 20) return <FaBatteryHalf className="text-yellow-500" />;
    return <FaBatteryQuarter className="text-red-500" />;
  };

  useEffect(() => {
    getBotData();
    const interval = setInterval(() => {
      getBotData();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-8 bg-white min-h-screen w-full">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="flex items-center gap-2 bg-[#133846] hover:scale-105 hover:bg-gray-700 text-white px-4 py-2 rounded-lg shadow-md transition duration-300"
      >
        <FaArrowLeft /> Back
      </button>

      {/* Page Title */}
      <h1 className="text-4xl font-bold mt-4 text-[#015d81]">Bot Status</h1>
      <p className="text-gray-500 text-lg mt-2">Last updated: {lastUpdate}</p>

      {/* Table Container */}
      <div className="mt-6 bg-[#015d81] p-6 shadow-md rounded-lg">
        <div className="overflow-x-auto">
          <SkeletonTheme baseColor="#ccc" highlightColor="#ddd">
            <table className="min-w-full border border-gray-200 rounded-lg overflow-hidden">
              <thead className="bg-white text-xl text-[#015d81]">
                <tr>
                  <th className="border border-gray-300 px-4 py-3">Name</th>
                  <th className="border border-gray-300 px-4 py-3">Nearest Tags</th>
                  <th className="border border-gray-300 px-4 py-3">Nearest Station</th>
                  <th className="border border-gray-300 px-4 py-3">ETA</th>
                  <th className="border border-gray-300 px-4 py-3">Voice</th>
                  <th className="border border-gray-300 px-4 py-3">Gesture</th>
                  <th className="border border-gray-300 px-4 py-3">Primary Battery</th>
                  <th className="border border-gray-300 px-4 py-3">Handbrake</th>
                  <th className="border border-gray-300 px-4 py-3">Auxiliary Battery</th>
                  <th className="border border-gray-300 px-4 py-3">Localization State</th>
                  <th className="border border-gray-300 px-4 py-3">AEB</th>
                  <th className="border border-gray-300 px-4 py-3">Robot State</th>
                  <th className="border border-gray-300 px-4 py-3">Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {botData.length === 0 ? (
                  <tr>
                    <td colSpan="13" className="px-4 py-3 text-center">Loading...</td>
                  </tr>
                ) : (
                  botData.map((bot, index) => {
                    // Check timestamp validity (within last 1 min)
                    const botTimestamp = bot.data?.timestamp?._seconds
                      ? new Date(bot.data.timestamp._seconds * 1000)
                      : null;
                    const isRecent =
                      botTimestamp && (new Date() - botTimestamp) / 1000 <= 60;
                    const rowColor = isRecent ? "bg-green-100" : "bg-gray-300";

                    return (
                      <tr
                        key={index}
                        className={`border border-gray-300 ${rowColor} hover:bg-gray-200 transition duration-300`}
                      >
                        <td className="px-4 py-3 text-center">{bot.name}</td>
                        <td className="px-4 py-3 text-center">{bot.data?.nearest_tags || "N/A"}</td>
                        <td className="px-4 py-3 text-center">{bot.data?.nearest_station || "N/A"}</td>
                        <td className="px-4 py-3 text-center">{bot.data?.eta || "N/A"}</td>
                        <td className="px-4 py-3 text-center">{bot.data?.voice ? "Yes" : "No"}</td>
                        <td className="px-4 py-3 text-center">{bot.data?.gesture ? "Yes" : "No"}</td>
                        <td className="px-4 py-3 text-center flex justify-center items-center gap-2">
                          {getBatteryIcon(bot.data?.primary_battery)} {bot.data?.primary_battery || "N/A"}%
                        </td>
                        
                        {/* Handbrake Status as a Button */}
                        <td className="px-4 py-3 text-center">
                          <span
                            className={`px-4 py-1 text-white font-bold rounded-lg ${
                              bot.data?.handbrake ? "bg-red-500" : "bg-green-500"
                            }`}
                          >
                            {bot.data?.handbrake ? "Yes" : "No"}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-center flex justify-center items-center gap-2">
                          {getBatteryIcon(bot.data?.auxiliary_battery)} {bot.data?.auxiliary_battery || "N/A"}%
                        </td>
                        {/* Localization State Always in Red */}
                        <td className="px-4 py-3 text-center">
                          <span
                            className={`px-4 py-1 text-white font-bold rounded-lg ${
                              bot.data?.localization_state === "localized"
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          >
                            {bot.data?.localization_state || "N/A"}
                          </span>
                        </td>
                        <td className="px-4 py-3 text-center"><span
                            className={`px-4 py-1 text-white font-bold rounded-lg ${
                              bot.data?.aeb === "localized"
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          >
                            {bot.data?.aeb || "N/A"}
                          </span></td>
                        {/* Robot State as a Button */}
                        <td className="px-4 py-3 text-center">
                          <span
                            className={`px-4 py-1 text-white font-bold rounded-lg ${
                              bot.data?.robot_state === "lost"
                                ? "bg-red-500"
                                : bot.data?.robot_state === "available"
                                ? "bg-green-500"
                                : bot.data?.robot_state === "engaged"
                                ? "bg-yellow-500"
                                : bot.data?.robot_state === "standby"
                                ? "bg-gray-500"
                                : "bg-gray-300"
                            }`}
                          >
                            {bot.data?.robot_state || "N/A"}
                          </span>
                        </td>

                        <td className="px-4 py-3 text-center">
                          {botTimestamp ? botTimestamp.toLocaleString() : "N/A"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </SkeletonTheme>
        </div>
      </div>
    </div>
  );
};

export default BotStatus;
