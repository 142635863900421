export default class Tags {
  constructor(botId) {
    this.botId = botId;
    this.botName = '';
    this.botCoordinateX = 0;
    this.botCoordinateY = 0;
    this.botBatteryStatus = 0;
    this.botStatus = '';
    this.botOrientation = '';
    this.botStation = 0;
  }
  setName(name) {
    this.botName = name;
  }
  setCoordinates(x, y) {
    this.botCoordinateX = x;
    this.botCoordinateY = y;
  }
  setBatteryStatus(battery) {
    this.botBatteryStatus = battery;
  }
  setOrientation(x) {
    this.botOrientation = x;
  }
  setStation(x) {
    this.botStation = x;
  }
  setStatus(x) {
    this.botStatus = x;
  }
  getCoordinates() {
    return [this.botCoordinateX, this.botCoordinateY];
  }
  getBattery() {
    return this.botBatteryStatus;
  }
  getStatus() {
    return this.botStatus;
  }
}
