import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

// Colors for each task type
const taskTypeColors = [
  '#A0E3D3', '#C7B5E1', '#4A7C59', '#B3C7E6', '#E6C7B3', 
  '#D3A0A0', '#C7E6B3', '#E6D3B3', '#D3C7E6', '#B3E6D3', 
  '#E6B3D3'
];

const TaskTypeSummary = ({ filteredTasks }) => {
  // Create an object to count tasks by type
  const taskTypeCounts = {
    goto: 0,
    transfer: 0,
    dock: 0,
    undock: 0,
    follow: 0,
    loop: 0,
    come: 0,
    fetch: 0,
    show: 0,
    cart_transfer: 0,
  };

  // Count tasks by type from filtered tasks
  filteredTasks.forEach((task) => {
    if (task.task_detail.task_type && taskTypeCounts[task.task_detail.task_type] !== undefined) {
      taskTypeCounts[task.task_detail.task_type]++;
    }
  });

  // Prepare data for the chart
  const data = Object.keys(taskTypeCounts).map((taskType, index) => ({
    name: taskType.charAt(0).toUpperCase() + taskType.slice(1), // Capitalize the task type name
    tasks: taskTypeCounts[taskType],
    color: taskTypeColors[index] || '#D3D3D3', // Assign color from the array
  }));

  return (
    <div style={containerStyle}>
      <h2 style={cardTitleStyle}>Total Tasks Performed by Type:</h2>
      <div style={chartAndListContainerStyle}>
        {/* Chart fills the upper half using ResponsiveContainer */}
        <div style={fullWidthChartStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="4 4" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend /> */}
              <Bar dataKey="tasks">
                {data.map((item, index) => (
                  <Cell key={`cell-${index}`} fill={item.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div style={taskDetailsStyle}>
          {data.map((item, index) => (
            <div key={index} style={taskItemStyle}>
              <div style={headerStyle}>
                <span
                  style={{ ...colorBoxStyle, backgroundColor: item.color }}
                ></span>
              </div>
              <div style={headerStyle}>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#374151',
                  }}
                >
                  {item.name}
                </span>
                <p style={taskValueStyle}>
                  {item.tasks} tasks
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Custom Tooltip to display task type on hover
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, tasks } = payload[0].payload;
    return (
      <div style={tooltipStyle}>
        <p>{`${name}: ${tasks} tasks`}</p>
      </div>
    );
  }
  return null;
};

// Inline styles
const containerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const cardTitleStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '16px',
};

const chartAndListContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '600px',
};

const fullWidthChartStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50%',
};

const taskDetailsStyle = {
  display: 'flex',
  alignItems: 'left',
  flexDirection: 'column',
  width: '100%',
  height: '50%', // Fixed height for the list to occupy the lower half
  overflowY: 'auto', // Enables scrolling
  marginTop: '5.5rem',
};

const taskItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '8px',
  backgroundColor: '#F3F4F6',
  borderRadius: '10px',
};

const colorBoxStyle = {
  display: 'inline-block',
  width: '4px',
  height: '36px',
  marginRight: '8px',
  marginLeft: '8px',
  borderRadius: '2px',
};

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '4px',
  marginTop: '4px',
};

const taskValueStyle = {
  fontWeight: '500',
  fontSize: '14px',
  color: '#111827',
};

const tooltipStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',
  padding: '8px',
  borderRadius: '4px',
};

export default TaskTypeSummary;
