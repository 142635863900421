import React, { useEffect, useState } from 'react';
import { useTheme } from '../Context/Context.js';
import Logo from '../../utils/Logo.png';
import { usePathDraw } from '../Context/Context.js';
import { useAIPathDraw } from '../Context/Context.js';
import { useRecordPath } from '../Context/Context.js';
import axios from 'axios';
import { useZone } from '../Context/Context.js';
import convertToReal from '../helper_functions/convertToRealCoordinates.js';
import { useNavigate } from 'react-router-dom';
import { addIntermediatePoints } from '../helper_functions/addIntermediatePoints.js';
import toast from 'react-hot-toast';
import { TagTwoTone } from '@mui/icons-material';
function SideBar({ cameraZoom }) {
  const { theme, setTheme } = useTheme();
  const {
    pathRecord,
    setPathRecord,
    showStation,
    setShowStation,
    showpath,
    setShowpath,
    hoveredPath,
    setHoveredPath,
    canvasWidth,
    setCanvasWidth,
    cameraOffset,
    setCameraOffset,
    showParking,
    setShowParking,
    selectedParking,
    setSelectedParking,
    selectedBot,
    setSelectedBot,
    selectedPath,
    setSelectedPath,
    selectedZone = [],
    setSelectedZone,
    showCartParking,
    setShowCartParking,
    selectedCartParking,
    setSelectedCartParking,
    selectedStation,
    setSelectedStation,
    selectedPeople,
    setSelectedPeople,
    showBots,
    showPeople,
    setShowPeople,
    setShowBots,
    showAprilTags,
    setShowAprilTags,
    showZone,
    setShowZone,
    showMaterials,
    setShowMaterials,
    stationRecord,
    setStationRecord,
    stationMarkedCoordinate,
    setStationMarkedCoordinate,
    tracePath,
    setTracePath,
    tagName,
    settagName,
    botTraceArray,
    setBotTraceArray,
    jitterbotCoordinates,
    setjitterbotCoordinates,
    parkingSpaceCoordinates,
    setParkingSpaceCoordinates,
    startParkingSpaceRecord,
    setStartParkingSpaceRecord,
    startCartParkingSpaceRecord,
    setStartCartParkingSpaceRecord,
    cartParkingSpaceCoordinates,
    setCartParkingSpaceCoordinates,
    selectedParkingSpace,
    setSelectedParkingSpace,
    traceTagName,
    settraceTagName,
    traceStation,
    setTraceStation,
    stationTraceArray,
    setStationTraceArray,
    stationTraceCoordinate,
    setStationTraceCoordinate,
    AprilTags,
    setAprilTags,
    AprilTagsCoordinates, 
    setAprilTagsCoordinates,
    isDejitterActive, setIsDejitterActive,
    DeJitterParam, setDeJitterParam,
    lastDeJitterValue, setLastDeJitterValue
  } = useRecordPath();
  const companydata = JSON.parse(window.sessionStorage.getItem('companyData'));
  const xFlipped = companydata.map_details.x_flip;
  const yFlipped = companydata.map_details.y_flip;
  const scaleFactor = companydata.map_details.scale;
  const originX = -companydata.map_details.origin_x / scaleFactor;
  const originY = companydata.map_details.origin_y / scaleFactor;
  const botMapping = JSON.parse(window.sessionStorage.getItem('botMapping'));
  const tags = JSON.parse(window.sessionStorage.getItem('tags'));
  const peopleMapping = JSON.parse(
    window.sessionStorage.getItem('PeopleMapping')
  );
  const filteredBots =
    tags && botMapping
      ? Object.keys(botMapping)
          .filter((tagId) => tags.hasOwnProperty(tagId))
          .map((tagId) => botMapping[tagId])
      : [];
  const filteredPeople =
    tags && peopleMapping
      ? Object.keys(peopleMapping)
          .filter((tagId) => tags.hasOwnProperty(tagId))
          .map((tagId) => peopleMapping[tagId])
      : [];
  // console.log(filteredPeople);

  const { ToRecord, setToRecord } = usePathDraw();
  const { AIpath, setAIpath, AIpathArray, setAIpathArray } = useAIPathDraw();
  const [toggle, setToggle] = useState(false);
  const [showList, setShowList] = useState(false);
  const [recordList, setRecordList] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [Bots, setBots] = useState(false);
  const [showSaveStationTagRecord, setShowSaveStationTagRecord] =
    useState(false);
  const [showIndividualBot, setshowIndividualBot] = useState(false);
  const [showIndividualPath, setshowIndividualPath] = useState(false);
  const [showIndividualStation, setshowIndividualStation] = useState(false);
  const [showIndividualPeople, setshowIndividualPeople] = useState(false);
  const [showIndividualZone, setShowIndividualZone] = useState(false);
  const [showIndividualCartParking, setShowIndividualCartParking] =
    useState(false);
  const [showIndividualParking, setShowIndividualParking] = useState(false);
  const [People, setPeople] = useState(false);
  const [Paths, setPaths] = useState(false);
  const [Materials, setMaterials] = useState(false);
  const [Zones, setZones] = useState(false);
  // const [Forklifts, setForklifts] = useState(false);
  const [Stations, setStations] = useState(false);
  const [showStationRecord, setShowStationRecord] = useState(false);
  const { ZonePointArray, setZonePointArray, CreateZone, setCreateZone } =
    useZone();
  const img = new Image();
  img.src = companydata.map_details.floor_plan_url;
  const pathData = window.sessionStorage.getItem('pathData');
  const paths = pathData ? JSON.parse(pathData).paths : [];
  const zoneData = window.sessionStorage.getItem('zoneData');
  const zones = zoneData ? JSON.parse(zoneData).zones : [];
  const stationData = window.sessionStorage.getItem('stationData');
  const cartParkingData = stationData;
  const userEmail = JSON.parse(window.sessionStorage.getItem('data')).email;
  const companyName = window.sessionStorage.getItem('companyName');
  const CompanyLogo = JSON.parse(
    window.sessionStorage.getItem('companyData')
  ).photo_url;
  const companymap = window.sessionStorage.getItem('companyName');
  const navigate = useNavigate();
  // console.log(companyName);
  //All the functions
  const handleSelectAll = () => {
    const newValue = !selectAll;
    setSelectAll(newValue);
    setBots(newValue);
    setPaths(newValue);
    setShowpath(newValue);
    setShowStation(newValue);
    setShowPeople(newValue);
    // setForklifts(newValue);
    setShowMaterials(newValue);
    setShowZone(newValue);
    setStations(newValue);
    setShowBots(newValue);
    setShowAprilTags(newValue);
    setSelectedBot(newValue ? filteredBots : []);
    setSelectedPeople(newValue ? filteredPeople : []);
    const allStations = stationData ? JSON.parse(stationData) : [];
    setSelectedStation(newValue ? allStations : []);
    setSelectedPath(newValue ? paths : []);
    setSelectedZone(newValue ? zones : []);
    // setShowCartParking(newValue);
    setShowParking(newValue);
    setSelectedParking(
      newValue
        ? JSON.parse(cartParkingData).filter((parking) => parking.bot_parking)
        : []
    );
    // setSelectedCartParking(
    //   newValue
    //     ? JSON.parse(cartParkingData).filter(
    //         (parking) => parking.cart_parking_empty || parking.cart_parking_full
    //       )
    //     : []
    // );
    console.log(selectedPeople);
  };
  useEffect(() => {
    // console.log(botTraceArray);
  }, [botTraceArray]);
  useEffect(() => {
    console.log("Updated isDejitterActive:", isDejitterActive);
  }, [isDejitterActive]);
  const BotsHandler = () => {
    setShowBots(!showBots);
  };
  const showAprilTagsHandler =() =>{
    setShowAprilTags(!showAprilTags);
  };
  const PathsHandler = () => {
    setPaths(!Paths);
    setShowpath(!showpath);
  };
  const PeopleHandler = () => {
    setShowPeople(!showPeople);
  };
  const handleParkingSpaceRecord = async () => {
    if (!startParkingSpaceRecord) {
      const stationOptions = JSON.parse(
        window.sessionStorage.getItem('stationData')
      ).map((station) => station.stationName);
      const selectedStation = window.prompt(
        `Enter the station name:\n${stationOptions.join('\n')}`
      );
      if (!selectedStation || !stationOptions.includes(selectedStation)) {
        alert('Invalid station name!');
        return;
      }

      await setParkingSpaceCoordinates((prev) => ({
        ...prev,
        station: selectedStation,
      }));

      console.log(parkingSpaceCoordinates);
    }
    setStartParkingSpaceRecord(!startParkingSpaceRecord);
  };

  // const handleStationTagRecord = () => {
  //   if (!startstationTagRecord) {
  //     console.log(stationTagRecord);
  //     setShowSaveStationTagRecord(true);

  //     const selectedTagforStationRecord = window.prompt("Enter tag Id");
  //     setSelectedTagStationRecord(selectedTagforStationRecord);
  //     setStartStationTagRecord(true);
  //     console.log(startstationTagRecord);

  //     setTimeout(() => {
  //       setStartStationTagRecord(false);
  //       console.log("false kr rhq hu",stationTagRecord,startstationTagRecord);
  //     }, 2000); // Set timeout to 6000ms (6 seconds)
  //     setTimeout(() => {
  //       // setStartStationTagRecord(false);
  //       console.log("false kr rhq hu2",stationTagRecord,startstationTagRecord);
  //     }, 6000); // Set timeout to 6000ms (6 seconds)
  //   }
  // };

  // const handleSaveStationTagRecord = async () => {
  //   let avgx = 0,
  //     avgy = 0;
  //     console.log(stationTagRecord);

  //   for (let i = 0; i < stationTagRecord.x.length; i++) {
  //     avgx += stationTagRecord.x[i];
  //     avgy += stationTagRecord.y[i];
  //   }
  //   console.log(
  //     avgx / stationTagRecord.x.length,
  //     avgy / stationTagRecord.x.length,
  //     startstationTagRecord
  //   );
  //   setStartStationTagRecord(false);
  //   setStationTagRecord({
  //     x: [],
  //     y: [],
  //   });
  //   console.log("stopped",startstationTagRecord,stationTagRecord);
  // };

  const handleCartParkingSpaceRecord = () => {
    if (!startCartParkingSpaceRecord) {
      const stationOptions = JSON.parse(
        window.sessionStorage.getItem('stationData')
      ).map((station) => station.stationName);
      const selectedStation = window.prompt(
        `Enter the station name:\n${stationOptions.join('\n')}`
      );
      const selectedstate = window.prompt('Enter state as full or empty');
      if (
        !selectedStation ||
        !stationOptions.includes(selectedStation) ||
        !selectedstate
      ) {
        alert('Invalid station name OR invalid state of parking space');
        return;
      }
      setCartParkingSpaceCoordinates((prev) => ({
        ...prev,
        station: selectedStation,
        state: selectedstate,
      }));
    }
    setStartCartParkingSpaceRecord(!startCartParkingSpaceRecord);
  };
  // useEffect(() => {
  //   console.log(
  //     "Updated cartParkingSpaceCoordinates:",
  //     cartParkingSpaceCoordinates
  //   );
  // }, [cartParkingSpaceCoordinates]);
  // const ForkliftsHandler = () => {
  //   setForklifts(!Forklifts);
  // };
  const MaterialsHandler = () => {
    setShowMaterials(!showMaterials);
  };
  const ZonesHandler = (e) => {
    setShowZone(e.target.checked);
  };
  const ZoneClickHandler = () => {
    setCreateZone(!CreateZone);
    console.log(CreateZone);
  };
  const StationsHandler = () => {
    setShowStation(!showStation);
  };
  const RemoteControlHandler = () => {
    //write the function to handle Remote Control event here
  };
  const UploadTaskHandler = () => {
    //write the function to handle Upload Task event here
  };
  const AnalyticsHandler = () => {
    //write the function to handle Analytics  event here(may be a redirect)
    navigate('/analytics');
  };
  const TagsHandler = () => {
    navigate('/tags');
  };
  const AIpathHandler = () => {
    setAIpath(!AIpath);
  };
  const clearPathHandler = async () => {
    setPathRecord({
      x: [],
      y: [],
    });
  };
  const handleDeletePath = async (pathname) => {
    if (window.confirm('Are you sure you want to delete this path?')) {
      try {
        await axios.post(
          'https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletepath',
          { pathname, companymap }
        );
        alert('Path deleted successfully!');
        setSelectedPath((prevSelectedPath) =>
          prevSelectedPath.filter((path) => path.pathname !== pathname)
        );
        const data = await axios.post(
          'https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata',
          { company: companymap }
        );
        window.sessionStorage.setItem('pathData', JSON.stringify(data.data));
      } catch (error) {
        console.error('Error deleting path:', error);
        alert('Failed to delete path.');
      }
    }
  };

  const handleDeleteStation = async (stationName) => {
    toast(
      (t) => (
        <div className="flex flex-col items-center gap-3 p-3">
          <p className="text-lg font-semibold">
            Are you sure you want to delete this station?
          </p>
          <div className="flex gap-4">
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition"
              onClick={async () => {
                toast.dismiss(t.id); // Close confirmation toast
  
                const deleteStationPromise = axios.post(
                  "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletestation",
                  { stationName, companymap }
                );
  
                toast.promise(deleteStationPromise, {
                  loading: "Deleting station...",
                  success: "Station deleted successfully!",
                  error: "Failed to delete station!",
                });
  
                try {
                  await deleteStationPromise;
                  
                  setSelectedStation((prevSelectedStations) =>
                    prevSelectedStations.filter(
                      (station) => station.stationName !== stationName
                    )
                  );
  
                  const data = await axios.post(
                    "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
                    { company: companyName }
                  );
  
                  window.sessionStorage.setItem(
                    "stationData",
                    JSON.stringify(data.data["stationdata"])
                  );
                } catch (error) {
                  console.error("Error deleting station:", error);
                }
              }}
            >
              Yes, Delete
            </button>
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition"
              onClick={() => toast.dismiss(t.id)} // Dismiss toast if canceled
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      { duration: Infinity } // Keeps the toast open until user responds
    );
  };

const handleDeleteParking = async (stationName) => {
  toast(
    (t) => (
      <div className="flex flex-col items-center gap-3 p-3">
        <p className="text-lg font-semibold">
          Are you sure you want to delete this parking zone?
        </p>
        <div className="flex gap-4">
          <button
            className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition"
            onClick={async () => {
              toast.dismiss(t.id);

              const deleteParkingPromise = axios.post(
                "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletebotparkingspace",
                { station: stationName, companyName }
              );

              toast.promise(deleteParkingPromise, {
                loading: "Deleting parking zone...",
                success: "Parking deleted successfully!",
                error: "Failed to delete parking zone.",
              });

              try {
                await deleteParkingPromise;
                setSelectedParkingSpace((prev) =>
                  prev.filter((station) => station.stationName !== stationName)
                );
                const data = await axios.post(
                  "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
                  { company: companyName }
                );
                window.sessionStorage.setItem(
                  "stationData",
                  JSON.stringify(data.data["stationdata"])
                );
              } catch (error) {
                console.error("Error deleting parking space:", error);
              }
            }}
          >
            Yes, Delete
          </button>
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ),
    { duration: Infinity }
  );
};

const handleDeleteCartParking = async (stationName) => {
  toast(
    (t) => (
      <div className="flex flex-col items-center gap-3 p-3">
        <p className="text-lg font-semibold">
          Are you sure you want to delete this cart parking zone?
        </p>
        <div className="flex gap-4">
          <button
            className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition"
            onClick={async () => {
              toast.dismiss(t.id);
              const state = window.prompt("Enter state as full or empty");

              const deleteCartParkingPromise = axios.post(
                "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletecartparkingspace",
                { stationName, companyName, state }
              );

              toast.promise(deleteCartParkingPromise, {
                loading: "Deleting cart parking zone...",
                success: "Cart parking deleted successfully!",
                error: "Failed to delete cart parking zone.",
              });

              try {
                await deleteCartParkingPromise;
                setSelectedParkingSpace((prev) =>
                  prev.filter((station) => station.stationName !== stationName)
                );
                const data = await axios.post(
                  "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata",
                  { company: companyName }
                );
                window.sessionStorage.setItem(
                  "stationData",
                  JSON.stringify(data.data["stationdata"])
                );
              } catch (error) {
                console.error("Error deleting cart parking space:", error);
              }
            }}
          >
            Yes, Delete
          </button>
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ),
    { duration: Infinity }
  );
};

const handleDeleteZone = async (zoneName) => {
  toast(
    (t) => (
      <div className="flex flex-col items-center gap-3 p-3">
        <p className="text-lg font-semibold">
          Are you sure you want to delete this zone?
        </p>
        <div className="flex gap-4">
          <button
            className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition"
            onClick={async () => {
              toast.dismiss(t.id);

              const deleteZonePromise = axios.post(
                "https://drobot-admin-v2-a2def93839bb.herokuapp.com/deletezone",
                { zoneName, companymap }
              );

              toast.promise(deleteZonePromise, {
                loading: "Deleting zone...",
                success: "Zone deleted successfully!",
                error: "Failed to delete zone.",
              });

              try {
                await deleteZonePromise;
                setSelectedZone((prev) =>
                  prev.filter((zone) => zone.zonename !== zoneName)
                );
                const data = await axios.post(
                  "https://drobot-admin-v2-a2def93839bb.herokuapp.com/getzonedata",
                  { company: companyName }
                );
                window.sessionStorage.setItem(
                  "zoneData",
                  JSON.stringify(data.data)
                );
              } catch (error) {
                console.error("Error deleting zone:", error);
              }
            }}
          >
            Yes, Delete
          </button>
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition"
            onClick={() => toast.dismiss(t.id)}
          >
            Cancel
          </button>
        </div>
      </div>
    ),
    { duration: Infinity }
  );
};


  const pathRecordHandler = async () => {
    const isAISmootheningActive = AIpathArray.x.length !== 0;
    console.log(pathRecord);
    let newpoints = [];
    let pathToSave = {};
    if (!isAISmootheningActive) {
      for (let i = 0; i < pathRecord.x.length; i++) {
        newpoints.push({ x: pathRecord.x[i], y: pathRecord.y[i] });
      }
      let newPoints = await addIntermediatePoints(newpoints, 10);
      let xList = [];
      let yList = [];
      for (let i = 0; i < newPoints.length; i++) {
        xList.push(newPoints[i].x);
        yList.push(newPoints[i].y);
      }
      pathToSave = { x: xList, y: yList };
    } else {
      pathToSave = AIpathArray;
    }
    if (
      isAISmootheningActive ? pathToSave.x.length === 0 : pathToSave.length == 0
    ) {
      alert('No path to save!');
      return;
    }
    const pathname = window.prompt('Enter Name:');
    if (
      window.sessionStorage.getItem('pathData') &&
      JSON.parse(window.sessionStorage.getItem('pathData')).paths.find(
        (path) => path.pathname === pathname
      )
    ) {
      alert('Pathname already exists');
      return;
    }
    const speed = window.prompt('Enter the speed:');
    const distance = window.prompt('Enter the distance:');
    const companyName = sessionStorage.getItem('companyName');
    const directionality = window.confirm(
      'Select Okay for directed and Cancel for undirected'
    );
    var convertedX = 0;
    var convertedXPath = [];
    var convertedY = 0;
    var convertedYPath = [];

    for (var i = 0; i < pathToSave.x.length; i++) {
      convertedX =
        ((pathToSave.x[i] / xFlipped +
          (xFlipped * img.naturalWidth) / 2 -
          xFlipped * originX) *
          scaleFactor) /
        10;
      convertedY =
        ((pathToSave.y[i] / yFlipped +
          (yFlipped * img.naturalHeight) / 2 -
          yFlipped * originY) *
          scaleFactor) /
        10;

      convertedXPath.push(convertedX);
      convertedYPath.push(convertedY);
    }

    let finalPath = {
      x: [...convertedXPath],
      y: [...convertedYPath],
    };

if (speed && distance) {
  const savePathPromise = axios.post(
    "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath",
    {
      path: finalPath,
      speed,
      distance,
      companyname: companyName,
      directionality: directionality ? "directed" : "undirected",
      pathname,
    }
  );

  toast.promise(savePathPromise, {
    loading: "Saving path...",
    success: "Path saved successfully!",
    error: "Error saving path.",
  });

  try {
    const response = await savePathPromise;
    console.log(response);

    setPathRecord({ x: [], y: [] });

    if (response.status === 200) {
      const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata", {
        company: companyName,
      });

      window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
      setAIpath(false);
      setAIpathArray({ x: [], y: [] });

      // Update the selected paths
      setSelectedPath(data.data.paths);
    }
  } catch (err) {
    console.error("Error saving path:", err);
    setPathRecord({ x: [], y: [] });
    setAIpath(false);
    setAIpathArray({ x: [], y: [] });
  }
} else {
  toast.error("Speed and distance are required!");
  setPathRecord({ x: [], y: [] });
}

setToRecord(false);
window.location.reload(false);
  }


const TracePathSaveHandler = async () => {
  if (botTraceArray.x.length === 0) {
    toast.error("No path has been traced!");
    setTracePath(false);
    return;
  }

  const convertedXPath = [];
  const convertedYPath = [];

  for (let i = 0; i < botTraceArray.x.length; i++) {
    const convertedX = botTraceArray.x[i] / 10;
    const convertedY = botTraceArray.y[i] / 10;
    convertedXPath.push(convertedX);
    convertedYPath.push(convertedY);
  }

  const convertedTraceArray = {
    x: convertedXPath,
    y: convertedYPath,
  };

  console.log("Bot Trace Array:", botTraceArray);
  console.log("Converted Trace Array:", convertedTraceArray);

  // Prompt user for speed and distance
  const pathname = window.prompt("Enter Name:");
  if (
    window.sessionStorage.getItem("pathData") &&
    JSON.parse(window.sessionStorage.getItem("pathData")).paths.find(
      (path) => path.pathname === pathname
    )
  ) {
    toast.error("Pathname already exists!");
    return;
  }

  const speed = window.prompt("Enter the speed:");
  const distance = window.prompt("Enter the distance:");
  const directionality = window.confirm(
    "Select OK for directed and Cancel for undirected"
  );

  if (!speed || !distance) {
    toast.error("Speed and Distance are required!");
    setTracePath(false);
    return;
  }

  const savePathPromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savepath", {
    path: convertedTraceArray,
    speed,
    distance,
    companyname: companyName,
    directionality: directionality ? "directed" : "undirected",
    pathname,
  });

  toast.promise(savePathPromise, {
    loading: "Saving path...",
    success: "Path saved successfully!",
    error: "Error saving path.",
  });

  try {
    const response = await savePathPromise;
    console.log("Save Response:", response);

    setBotTraceArray({ x: [], y: [] });
    setTracePath(false);

    if (response.status === 200) {
      const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getpathdata", {
        company: companyName,
      });

      window.sessionStorage.setItem("pathData", JSON.stringify(data.data));
    }
  } catch (err) {
    console.error("Error saving path:", err);
    setTracePath(false);
    setBotTraceArray({ x: [], y: [] });
  }

  window.location.reload(false);
};


  const clearTracedPathHandler = () => {
    setBotTraceArray({
      x: [],
      y: [],
    });
    setjitterbotCoordinates({
      x: [],
      y: [],
    });
    setTracePath(!tracePath);
    setAIpath(!AIpath);
    window.location.reload();
  };
  const TracePathHandler = () => {
    const tagName = window.prompt('Enter the tag name to trace:');
    if (tagName) {
      settagName(tagName);
      setTracePath(!tracePath);
      if (tracePath == false) {
        setAIpath(false);
      }
      setAIpathArray({
        x: [],
        y: [],
      });
    } else {
      alert(`Tag with ID "${tagName}" not found!`);
      setAIpath(false);
    }
  };

  const TraceStationHandler = () => {
    const tagName = window.prompt('Enter the tag name to trace:');
    if (tagName) {
      settraceTagName(tagName);
      setTraceStation(!traceStation);
    } else {
      alert(`Tag with ID "${tagName}" not found!`);
    }
  };

  const AprilTagsHandler = () => {
    setAprilTags(!AprilTags);
  };
  

  const TraceStationSaveHandler = async () => {
    const stationConvertedCoordinates = convertToReal(
      stationTraceCoordinate.x,
      stationTraceCoordinate.y
    );
  
    const nameStation = window.prompt("Enter the name of Station:");
    const companyName = window.sessionStorage.getItem("companyName");
  
    if (!nameStation || nameStation.trim().length === 0) {
      toast.error("Station Name cannot be empty!");
      return;
    }
  
    const saveStationPromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savestation", {
      stationCoordinate: {
        x: stationConvertedCoordinates.convertedX,
        y: stationConvertedCoordinates.convertedY,
      },
      companyname: companyName,
      stationName: nameStation,
    });
  
    toast.promise(saveStationPromise, {
      loading: "Saving station...",
      success: "Station saved successfully!",
      error: "Error saving station.",
    });
  
    try {
      const res = await saveStationPromise;
  
      if (res.status === 200) {
        const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata", {
          company: companyName,
        });
  
        window.sessionStorage.setItem("stationData", JSON.stringify(data.data["stationdata"]));
        setSelectedStation(JSON.parse(window.sessionStorage.getItem("stationData")));
      }
    } catch (error) {
      console.log(error);
      setStationMarkedCoordinate([], []);
    }
  
    setStationTraceArray({ x: [], y: [] });
    setStationTraceCoordinate({ x: null, y: null });
    setTraceStation(!traceStation);
  };
  
  const AprilTagsSaveHandler = async () => {
    const AprilTagsConvertedCoordinates = convertToReal(
      AprilTagsCoordinates.x,
      AprilTagsCoordinates.y
    );
  
    let angleconversion2 = 0;
    let angleconversion1 = 0;
  
    if (AprilTagsCoordinates.yaw != null) {
      angleconversion1 =
        xFlipped == -1
          ? AprilTagsCoordinates.yaw < 0
            ? -180 - AprilTagsCoordinates.yaw
            : 180 - AprilTagsCoordinates.yaw
          : AprilTagsCoordinates.yaw;
      angleconversion2 = yFlipped == -1 ? -angleconversion1 : angleconversion1;
    }
  
    const Size = window.prompt("Enter the size of April Tags:");
    const TagID = window.prompt("Enter the Tag ID of April Tags:");
    const companyName = window.sessionStorage.getItem("companyName");
  
    if (!Size || Size.trim().length === 0) {
      toast.error("Size cannot be empty!");
      return;
    }
  
    const saveAprilTagsPromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/saveapriltags", {
      AprilTagsCoordinate: {
        x: AprilTagsConvertedCoordinates.convertedX,
        y: AprilTagsConvertedCoordinates.convertedY,
        yaw: angleconversion2,
      },
      companyname: companyName,
      Size,
      TagID,
    });
  
    toast.promise(saveAprilTagsPromise, {
      loading: "Saving April Tags...",
      success: "April Tags saved successfully!",
      error: "Error saving April Tags.",
    });
  
    try {
      const res= await saveAprilTagsPromise;
      if (res.status === 200) {
        const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata", {
          company: companyName,
        });
        window.sessionStorage.setItem('AprilTagsData',JSON.stringify(data.data.apriltagsdata));
      }
    } catch (error) {
      console.log(error);
      setStationMarkedCoordinate([], []);
    }
  
    setAprilTagsCoordinates({ x: null, y: null, yaw: 0 });
    setAprilTags(false);
  };
  
  const clearTracedStationHandler = () => {
    setStationTraceArray({
      x: [],
      y: [],
    });
    setStationTraceCoordinate({
      x: null,
      y: null,
    });
    setTraceStation(!traceStation);
  };

  const clearAprilTagsHandler = () => {
    setAprilTagsCoordinates({
      x: null,
      y: null,
      yaw:0
    });
    setAprilTags(false);
  };

  const recordStationHandler = () => {
    setShowStationRecord(!showStationRecord);
    if (stationRecord == true) {
      setStationRecord(false);
      setStationMarkedCoordinate({
        x: null,
        y: null,
      });
    } else {
      setStationRecord(true);
    }
  };

  const stationSave = async () => {
    const stationConvertedCoordinates = convertToReal(
      stationMarkedCoordinate.x,
      stationMarkedCoordinate.y
    );
  
    const nameStation = window.prompt("Enter the name of Station:");
    const companyName = window.sessionStorage.getItem("companyName");
  
    if (!nameStation || nameStation.trim().length === 0) {
      toast.error("Station Name cannot be empty!");
      return;
    }
  
    const saveStationPromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savestation", {
      stationCoordinate: {
        x: stationConvertedCoordinates.convertedX,
        y: stationConvertedCoordinates.convertedY,
      },
      companyname: companyName,
      stationName: nameStation,
    });
  
    toast.promise(saveStationPromise, {
      loading: "Saving station...",
      success: "Station saved successfully!",
      error: "Error saving station.",
    });
  
    try {
      const res = await saveStationPromise;
  
      if (res.status === 200) {
        const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata", {
          company: companyName,
        });
  
        window.sessionStorage.setItem("stationData", JSON.stringify(data.data["stationdata"]));
        setSelectedStation(JSON.parse(window.sessionStorage.getItem("stationData")));
        setStationMarkedCoordinate([], []);
      }
    } catch (error) {
      console.log(error);
      setStationMarkedCoordinate([], []);
    }
  
    setShowStationRecord(!showStationRecord);
    window.location.reload(false);
  };
  
  const ZoneSaveHandler = async () => {
    if (ZonePointArray.x.length < 3) {
      toast.error("Please select at least 3 points to create a zone!");
      return;
    }
  
    let convertedZonePointsArray = {
      x: [],
      y: [],
    };
  
    for (let i = 0; i < ZonePointArray.x.length; i++) {
      const convertedZonePoints = await convertToReal(
        ZonePointArray.x[i],
        ZonePointArray.y[i]
      );
  
      convertedZonePointsArray.x.push(convertedZonePoints.convertedX);
      convertedZonePointsArray.y.push(convertedZonePoints.convertedY);
    }
  
    // Trim the first and last points
    convertedZonePointsArray.x = convertedZonePointsArray.x.slice(1, -1);
    convertedZonePointsArray.y = convertedZonePointsArray.y.slice(1, -1);
  
    const companyName = window.sessionStorage.getItem("companyName");
    const zoneName = window.prompt("Enter the name of the Zone:");
  
    if (!zoneName || zoneName.trim().length === 0) {
      toast.error("Zone name cannot be empty!");
      return;
    }
  
    const saveZonePromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savezone", {
      zonePoints: convertedZonePointsArray,
      companyname: companyName,
      zoneName,
    });
  
    toast.promise(saveZonePromise, {
      loading: "Saving zone...",
      success: "Zone saved successfully!",
      error: "Error saving zone.",
    });
  
    try {
      const res = await saveZonePromise;
  
      if (res.status === 200) {
        const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getzonedata", {
          company: companyName,
        });
  
        window.sessionStorage.setItem("zoneData", JSON.stringify(data.data));
        setZonePointArray({ x: [], y: [] });
  
        if (zoneData) {
          setSelectedZone(data.data.zones);
        } else {
          setSelectedZone([]);
        }
      }
    } catch (error) {
      console.log(error);
      setZonePointArray({ x: [], y: [] });
    }
  
    window.location.reload(false);
  };
  

  const clearStationHandler = async () => {
    setStationMarkedCoordinate({
      x: null,
      y: null,
    });
    setStationRecord(true);
  };
  const handleClearBotParkingSpace = () => {
    setParkingSpaceCoordinates({
      ...parkingSpaceCoordinates,
      x: null,
      y: null,
      yaw: null,
    });
    setStartParkingSpaceRecord(true);
  };
  const handleClearCartParkingSpace = () => {
    setCartParkingSpaceCoordinates({
      ...cartParkingSpaceCoordinates,
      P: {
        center: {
          x: null,
          y: null,
        },
        x: [null, null, null, null],
        y: [null, null, null, null],
      },
      P_Prime: {
        x: null,
        y: null,
      },
      P1: {
        x: null,
        y: null,
      },
      P2: {
        x: null,
        y: null,
      },
      yaw: 0,
    });
    setStartCartParkingSpaceRecord(true);
  };

  const handleThemeToggle = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  const PathDrawHandler = () => {
    if (ToRecord == true) {
      setToRecord((prev) => {
        console.log('Previous State:', prev);
        return false;
      });
      setPathRecord({
        x: [],
        y: [],
      });
      setAIpath(false);
    } else {
      setToRecord(true);
    }
    
  };


const handleSaveBotParkingSpace = async () => {
  if (
    parkingSpaceCoordinates.x == null ||
    parkingSpaceCoordinates.y == null ||
    parkingSpaceCoordinates.station == null
  ) {
    toast.error("Invalid Data. Please enter valid coordinates and station.");
    return;
  }

  const convertedCoordinates = await convertToReal(
    parkingSpaceCoordinates.x,
    parkingSpaceCoordinates.y
  );

  let angleconversion2 = 0;
  let angleconversion1 = 0;

  if (parkingSpaceCoordinates.yaw != null) {
    angleconversion1 =
      xFlipped == -1
        ? parkingSpaceCoordinates.yaw < 0
          ? -180 - parkingSpaceCoordinates.yaw
          : 180 - parkingSpaceCoordinates.yaw
        : parkingSpaceCoordinates.yaw;
    angleconversion2 = yFlipped == -1 ? -angleconversion1 : angleconversion1;
  }

  const data = {
    station: parkingSpaceCoordinates.station,
    x: convertedCoordinates.convertedX,
    y: convertedCoordinates.convertedY,
    yaw: angleconversion2,
  };

  const company = window.sessionStorage.getItem("companyName");

  const saveParkingPromise = axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/savebotparkingspace", {
    data: data,
    companyName: company,
  });

  toast.promise(saveParkingPromise, {
    loading: "Saving bot parking space...",
    success: `Bot parking space saved successfully for station: ${parkingSpaceCoordinates.station}`,
    error: "Error saving bot parking space.",
  });

  try {
    const res = await saveParkingPromise;

    if (res.status === 200) {
      const data = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata", {
        company: companyName,
      });

      window.sessionStorage.setItem("stationData", JSON.stringify(data.data["stationdata"]));
      setSelectedStation(JSON.parse(window.sessionStorage.getItem("stationData")));
      setSelectedParking(
        JSON.parse(window.sessionStorage.getItem("stationData")).filter(
          (parking) => parking.bot_parking
        )
      );
    }
  } catch (error) {
    console.error("Error saving bot parking space:", error);
  }

  setParkingSpaceCoordinates({ station: null, x: null, y: null, yaw: null });
  setStartParkingSpaceRecord(false);
  window.location.reload(false);
};

const handleSaveCartParkingSpace = async () => {
  if (
    !cartParkingSpaceCoordinates.P.center.x ||
    !cartParkingSpaceCoordinates.station ||
    !cartParkingSpaceCoordinates.state
  ) {
    toast.error("Invalid Data. Ensure station, state, and coordinates are set.");
    return;
  }

  const convertCoordinate = async (x, y) => {
    const { convertedX, convertedY } = await convertToReal(x, y);
    return { x: convertedX, y: convertedY };
  };

  const convertedCenter = await convertCoordinate(
    cartParkingSpaceCoordinates.P.center.x,
    cartParkingSpaceCoordinates.P.center.y
  );

  const convertedPArray = await Promise.all(
    cartParkingSpaceCoordinates.P.x.map((x, i) =>
      convertCoordinate(x, cartParkingSpaceCoordinates.P.y[i])
    )
  );

  const convertedP_Prime = await convertCoordinate(
    cartParkingSpaceCoordinates.P_Prime.x,
    cartParkingSpaceCoordinates.P_Prime.y
  );
  const convertedP1 = await convertCoordinate(
    cartParkingSpaceCoordinates.P1.x,
    cartParkingSpaceCoordinates.P1.y
  );
  const convertedP2 = await convertCoordinate(
    cartParkingSpaceCoordinates.P2.x,
    cartParkingSpaceCoordinates.P2.y
  );

  let angleconversion1 = 0;
  let angleconversion2 = 0;

  if (cartParkingSpaceCoordinates.yaw != null) {
    angleconversion1 =
      xFlipped == -1
        ? cartParkingSpaceCoordinates.yaw < 0
          ? -180 - cartParkingSpaceCoordinates.yaw
          : 180 - cartParkingSpaceCoordinates.yaw
        : cartParkingSpaceCoordinates.yaw;
    angleconversion2 = yFlipped == -1 ? -angleconversion1 : angleconversion1;
  }

  const data = {
    P: {
      center: convertedCenter,
      x: convertedPArray.map((point) => point.x),
      y: convertedPArray.map((point) => point.y),
    },
    P_Prime: convertedP_Prime,
    P1: convertedP1,
    P2: convertedP2,
    yaw: angleconversion2,
    station: cartParkingSpaceCoordinates.station,
    state: cartParkingSpaceCoordinates.state,
  };

  const saveCartParkingPromise = axios.post(
    "https://drobot-admin-v2-a2def93839bb.herokuapp.com/savecartparkingspace",
    { data: data, companyName: companyName },
    { headers: { "Content-Type": "application/json" } }
  );

  toast.promise(saveCartParkingPromise, {
    loading: "Saving cart parking space...",
    success: `Cart parking space saved successfully for station: ${data.station}`,
    error: "Error saving cart parking space.",
  });

  try {
    const res = await saveCartParkingPromise;

    if (res.status === 200) {
      const companyDataRes = await axios.post("https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompanydata", {
        company: companyName,
      });

      const stationData = companyDataRes.data["stationdata"];
      window.sessionStorage.setItem("stationData", JSON.stringify(stationData));

      window.sessionStorage.setItem(
        "cartParkingSpaceData",
        JSON.stringify(
          stationData
            .map((station) => {
              if (
                station.cart_parking_full != null ||
                station.cart_parking_empty != null
              ) {
                return {
                  station: station.stationName,
                  full: station.cart_parking_full,
                  empty: station.cart_parking_empty,
                };
              }
              return null;
            })
            .filter((parking) => parking !== null)
        )
      );

      setSelectedStation(JSON.parse(window.sessionStorage.getItem("stationData")));
      setSelectedCartParking(
        JSON.parse(window.sessionStorage.getItem("stationData")).filter(
          (parking) => parking.cart_parking_full || parking.cart_parking_empty
        )
      );
    }
  } catch (error) {
    console.error("Error saving cart parking space:", error);
  }

  setCartParkingSpaceCoordinates({
    station: null,
    state: null,
    P: {
      center: { x: null, y: null },
      x: [null, null, null, null],
      y: [null, null, null, null],
    },
    P_Prime: { x: null, y: null },
    P1: { x: null, y: null },
    P2: { x: null, y: null },
    yaw: 0,
  });

  setStartCartParkingSpaceRecord(false);
  window.location.reload(false);
};

  const handleLogout = () => {
    const confirmLogout = toast(
      (t) => (
        <div className="flex flex-col items-center gap-3 p-3">
          <p className="text-lg font-semibold">
            Are you sure you want to logout?
          </p>
          <div className="flex gap-4">
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition"
              onClick={() => {
                toast.dismiss(t.id); // Close the confirmation toast

                // Perform logout
                localStorage.removeItem('auth');
                sessionStorage.clear();

                // Show success message
                toast.success('Logged out successfully!', {
                  duration: 3000, // Shows success message for 3 seconds
                  style: { background: '#4CAF50', color: 'white' }, // Green success styling
                });

                // Navigate after a short delay
                setTimeout(() => {
                  navigate('/login');
                }, 1500);
              }}
            >
              Yes, Logout
            </button>
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 transition"
              onClick={() => toast.dismiss(t.id)} // Properly dismiss the toast on cancel
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      { duration: Infinity } // Keeps the confirmation toast open until user selects an option
    );
  };

  return (
    <>
      <div
        className={` h-screen font-sans transition-all ${
          toggle ? ' w-[80px]' : 'w-60'
        }`}
        style={{
          backgroundColor: theme === 'light' ? '#015D81' : '#0C2F3A',
          position: 'sticky',
          top: '0',
          zIndex: '1000',
        }}
        // backgroundColor="015D81"
      >
        <div
          className={`relative max-h-screen left-0 h-screen overflow-hidden transition-all ${
            toggle ? 'w-[80px]' : 'w-60'
          } `}
          style={{ backgroundColor: theme === 'light' ? '#015D81' : '#0C2F3A' }}
          // backgroundColor="015D81"
        >
          <div
            className={` flex flex-wrap items-center justify-between ${
              toggle ? 'py-2' : 'p-2'
            } gap-2 `}
          >
            <div
              className={`${
                toggle ? 'w-[100%]' : ' w-max'
              } flex gap-2 justify-evenly items-center`}
            >
              <img
                src={toggle ? Logo : 'svgs/drobot_logo.svg'}
                alt="Drobot Logo"
                className={` self-center h-[2.5rem] mt-5  `}
              />
            </div>

            <div
              className={`${
                toggle ? 'w-[100%]' : 'w-auto'
              } flex gap-2 justify-evenly items-center`}
            >
              <button
                className="text-white text-xl"
                onClick={() => setToggle(!toggle)}
              >
                <img
                  src={'svgs/toggle.svg'}
                  alt="Toggle"
                  className="w-4 h-4 mt-4"
                />
              </button>
            </div>
          </div>
          {/* UI when SideBar is Open */}
          {!toggle ? (
            <div className=" text-sm w-full text-white p-2 dropdown-container h-[65%] overflow-y-auto">
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                GENERAL
              </div>
              <div className="flex justify-between items-center w-full px-4 py-2 rounded-md text-white">
  <span className="text-sm font-medium tracking-wide">De-Jitter</span>
<button
  onClick={() => {
   if(!isDejitterActive)
   {
    setIsDejitterActive(true);
    navigate('/settings');
   }
   else
   setIsDejitterActive(false);
  }}
  className={`relative w-12 h-5 flex items-center rounded-full p-1 transition-all duration-300 ease-in-out 
    ${isDejitterActive ? 'bg-[#4CAF50]' : 'bg-gray-500'}`}
>
  <div
    className={`absolute w-3 h-3 bg-white rounded-full shadow-md transform transition-all duration-300 
      ${isDejitterActive ? 'translate-x-7' : 'translate-x-1'}`}
  ></div>
</button>
</div>

              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2  justify-between items-center cursor-pointer"
                  onClick={() => setShowList(!showList)}
                >
                  <div className="flex justify-center p-1 items-center gap-2 ">
                    <img
                      src="svgs/show.svg"
                      alt="Toggle"
                      className="w-5.5 h-5.5"
                    />
                    <span>Show</span>
                  </div>
                  <span className="ml-2 ">
                    {showList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {showList && (
                  <>
                    <div className="flex hover:bg-[#FFFFFF1F] rounded-md cursor-pointer text-[0.9rem] ml-4 gap-2 px-2 py-1 justify-start items-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                      <div>Select all</div>
                    </div>
                    <div className=" mt-2 ml-4 mb-2 list-none">
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showBots}
                            onChange={(e) => {
                              BotsHandler(e);
                              const filteredBots = e.target.checked
                                ? Object.keys(botMapping)
                                    .filter((tagId) =>
                                      tags.hasOwnProperty(tagId)
                                    )
                                    .map((tagId) => botMapping[tagId])
                                : [];
                              setSelectedBot(filteredBots);
                            }}
                          />
                          <div
                            className="flex gap-[0.5rem] w-[100%]"
                            onClick={() =>
                              setshowIndividualBot(!showIndividualBot)
                            }
                          >
                            <img
                              src="svgs/bots.svg"
                              alt="Bots"
                              className="w-[22px] h-[22px]"
                            />
                            <span>Bots</span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualBot(!showIndividualBot)
                          }
                        >
                          {showIndividualBot ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>
                      {showIndividualBot && botMapping && tags && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {Object.keys(tags)
                            .filter((tagId) => botMapping[tagId])
                            .map((tagId, index) => {
                              const botName = botMapping[tagId];
                              return (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedBot((prevBots) => {
                                      const isBotSelected =
                                        Array.isArray(prevBots) &&
                                        prevBots.includes(botName);
                                      let newBots;

                                      if (isBotSelected) {
                                        newBots = prevBots.filter(
                                          (b) => b !== botName
                                        );
                                      } else {
                                        newBots = [...prevBots, botName];
                                      }
                                      const allBotsSelected = Object.keys(tags)
                                        .map((id) => botMapping[id])
                                        .filter(Boolean)
                                        .every((name) =>
                                          newBots.includes(name)
                                        );

                                      setShowBots(allBotsSelected);
                                      return newBots;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      Array.isArray(selectedBot) &&
                                      selectedBot.includes(botName)
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedBot((prevBots) => {
                                        let newBots;
                                        if (isChecked) {
                                          newBots = [...prevBots, botName];
                                        } else {
                                          newBots = prevBots.filter(
                                            (b) => b !== botName
                                          );
                                        }
                                        const allBotsSelected = Object.keys(
                                          tags
                                        )
                                          .map((id) => botMapping[id])
                                          .filter(Boolean)
                                          .every((name) =>
                                            newBots.includes(name)
                                          );
                                        setShowBots(allBotsSelected);
                                        return newBots;
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {botName}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showpath}
                            onChange={(e) => {
                              PathsHandler(e);
                              setSelectedPath(e.target.checked ? paths : []); // Select all paths if checked, deselect all if unchecked
                            }}
                          />
                          <div
                            className="flex gap-[0.5rem] w-[100%]"
                            onClick={() =>
                              setshowIndividualPath(!showIndividualPath)
                            }
                          >
                            <img
                              src="svgs/paths.svg"
                              alt="Paths"
                              className="w-[22px] h-[22px]"
                            />
                            <span>Paths</span>
                          </div>
                          <button
                            onClick={() =>
                              setshowIndividualPath(!showIndividualPath)
                            }
                          >
                            {showIndividualPath ? (
                              <img
                                src="svgs/arrow_up.svg"
                                alt="Toggle"
                                className="w-5.5 h-5.5"
                              />
                            ) : (
                              <img
                                src="svgs/arrow_right.svg"
                                alt="Arrow"
                                className="w-5.5 h-5.5 cursor-pointer"
                              />
                            )}
                          </button>
                        </div>
                      </button>

                      {showIndividualPath && paths && paths.length > 0 && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {paths.map((path, index) => (
                            <li
                              key={index}
                              className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                              onMouseEnter={() => setHoveredPath(path)} // Set the hovered path
                              onMouseLeave={() => setHoveredPath(null)} // Clear the hovered path
                              onClick={() => {
                                setSelectedPath((prevPaths) => {
                                  const isPathSelected = prevPaths.some(
                                    (p) => p.pathname === path.pathname
                                  );
                                  let newPaths;

                                  if (isPathSelected) {
                                    newPaths = prevPaths.filter(
                                      (p) => p.pathname !== path.pathname
                                    );
                                  } else {
                                    newPaths = [...prevPaths, path];
                                  }

                                  const allPathsSelected =
                                    paths.length > 0 &&
                                    paths.every((p) =>
                                      newPaths.some(
                                        (np) => np.pathname === p.pathname
                                      )
                                    );
                                  setShowpath(allPathsSelected); // If all paths are selected, mark showpath as true
                                  return newPaths;
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  !!selectedPath.find(
                                    (p) => p.pathname === path.pathname
                                  )
                                } // Check if the path is in selectedPath
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const isChecked = e.target.checked;
                                  setSelectedPath((prevPaths) => {
                                    if (isChecked) {
                                      const newPaths = [...prevPaths, path];
                                      const allPathsSelected =
                                        paths.length > 0 &&
                                        paths.every((p) =>
                                          newPaths.some(
                                            (np) => np.pathname === p.pathname
                                          )
                                        );
                                      setShowpath(allPathsSelected); // If all paths are selected, mark showpath as true
                                      return newPaths;
                                    } else {
                                      const newPaths = prevPaths.filter(
                                        (p) => p.pathname !== path.pathname
                                      );
                                      setShowpath(false); // Since one path is unchecked, uncheck showpath
                                      return newPaths;
                                    }
                                  });
                                }}
                                className="mr-2"
                              />
                              {path.pathname.length > 12
                                ? `${path.pathname.slice(0, 12)}...`
                                : path.pathname}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent onClick for the parent <li>
                                  handleDeletePath(path.pathname);
                                }}
                                className="ml-auto text-red-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                  <path d="M10 11v6" />
                                  <path d="M14 11v6" />
                                  <path d="M5 6l1 14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-14" />
                                </svg>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showPeople}
                            onChange={(e) => {
                              PeopleHandler(e);

                              // Filter peopleMapping to include only those whose tagId exists in tags
                              const filteredPeople = e.target.checked
                                ? Object.keys(peopleMapping)
                                    .filter((tagId) =>
                                      tags.hasOwnProperty(tagId)
                                    ) // Check if tagId exists in tags
                                    .map((tagId) => peopleMapping[tagId]) // Get the corresponding person's name
                                : [];

                              setSelectedPeople(filteredPeople); // Set only the filtered names
                            }}
                          />
                          <div
                            className="flex gap-[0.5rem] w-[100%]"
                            onClick={() =>
                              setshowIndividualPeople(!showIndividualPeople)
                            }
                          >
                            <img
                              src="svgs/people.svg"
                              alt="People"
                              className="w-[22px] h-[22px]"
                            />
                            <span>People</span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualPeople(!showIndividualPeople)
                          }
                        >
                          {showIndividualPeople ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>
                      {showIndividualPeople && peopleMapping && tags && (
                        <ul className="path-list rounded-md pl-6 mr-2 mb-4">
                          {Object.keys(tags)
                            .filter((tagId) => peopleMapping[tagId])
                            .map((tagId, index) => {
                              const person = tags[tagId];
                              return (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedPeople((prevPeople) => {
                                      const personName = peopleMapping[tagId];
                                      const isPersonSelected =
                                        Array.isArray(prevPeople) &&
                                        prevPeople.includes(personName);
                                      let newPeople;
                                      if (isPersonSelected) {
                                        newPeople = prevPeople.filter(
                                          (p) => p !== personName
                                        );
                                      } else {
                                        newPeople = [...prevPeople, personName];
                                      }
                                      const allPeopleWithTags = Object.keys(
                                        tags
                                      )
                                        .map((id) => peopleMapping[id])
                                        .filter(Boolean);
                                      const allSelected =
                                        allPeopleWithTags.every((name) =>
                                          newPeople.includes(name)
                                        );
                                      setShowPeople(allSelected);
                                      return newPeople;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      Array.isArray(selectedPeople) &&
                                      selectedPeople.includes(
                                        peopleMapping[tagId]
                                      )
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedPeople((prevPeople) => {
                                        const personName = peopleMapping[tagId];
                                        let newPeople;

                                        if (isChecked) {
                                          newPeople = [
                                            ...prevPeople,
                                            personName,
                                          ];
                                        } else {
                                          newPeople = prevPeople.filter(
                                            (p) => p !== personName
                                          );
                                        }
                                        const allPeopleWithTags = Object.keys(
                                          tags
                                        )
                                          .map((id) => peopleMapping[id])
                                          .filter(Boolean);
                                        const allSelected =
                                          allPeopleWithTags.every((name) =>
                                            newPeople.includes(name)
                                          );
                                        setShowPeople(allSelected);
                                        return newPeople;
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {peopleMapping[tagId]}{' '}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                      {/* <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={Forklifts}
                            onChange={ForkliftsHandler}
                          />
                          <img
                            src="svgs/forklifts.svg"
                            alt="Forklifts"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Forklifts</span>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button> */}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showMaterials}
                            onChange={MaterialsHandler}
                          />
                          <div className="flex gap-[0.5rem] w-[100%]">
                            <img
                              src="svgs/materials.svg"
                              alt="Materials"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Materials</span>
                          </div>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showAprilTags}
                            onChange={showAprilTagsHandler}
                          />
                          <div className="flex gap-[0.5rem] w-[100%]">
                            <img
                              src="svgs/materials.svg"
                              alt="Materials"
                              className="w-[22px] h-[22px] "
                            />
                            <span>AprilTags</span>
                          </div>
                        </div>
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      </button>
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={
                              stationData.length > 0 &&
                              JSON.parse(stationData).every((station) =>
                                selectedStation.some(
                                  (s) => s.stationName === station.stationName
                                )
                              )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowStation(isChecked); // Set the showStation checkbox state
                              setSelectedStation(
                                isChecked ? JSON.parse(stationData) : []
                              ); // Select all stations if checked, deselect all if unchecked
                            }}
                          />
                          <div
                            onClick={() =>
                              setshowIndividualStation(!showIndividualStation)
                            }
                            className="flex gap-[0.5rem] w-[100%]"
                          >
                            <img
                              src="svgs/stations.svg"
                              alt="Stations"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Stations</span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setshowIndividualStation(!showIndividualStation)
                          }
                        >
                          {showIndividualStation ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualStation &&
                        stationData &&
                        JSON.parse(stationData).length > 0 && (
                          <ul className="station-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(stationData).map((station, index) => (
                              <li
                                key={index}
                                className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                onClick={() => {
                                  setSelectedStation((prevStations) => {
                                    const isStationSelected = prevStations.some(
                                      (s) =>
                                        s.stationName === station.stationName
                                    );
                                    let newStations;

                                    if (isStationSelected) {
                                      newStations = prevStations.filter(
                                        (s) =>
                                          s.stationName !== station.stationName
                                      );
                                    } else {
                                      newStations = [...prevStations, station];
                                    }

                                    // Update the `showStation` state
                                    const allStationsSelected =
                                      stationData.length > 0 &&
                                      JSON.parse(stationData).every((s) =>
                                        newStations.some(
                                          (ns) =>
                                            ns.stationName === s.stationName
                                        )
                                      );
                                    setShowStation(allStationsSelected); // If all stations are selected, mark showStation as true
                                    return newStations;
                                  });
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    !!selectedStation.find(
                                      (s) =>
                                        s.stationName === station.stationName
                                    )
                                  } // Check if the station is in selectedStation
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    const isChecked = e.target.checked;
                                    setSelectedStation((prevStations) => {
                                      if (isChecked) {
                                        const newStations = [
                                          ...prevStations,
                                          station,
                                        ];
                                        const allStationsSelected =
                                          stationData.length > 0 &&
                                          JSON.parse(stationData).every((s) =>
                                            newStations.some(
                                              (ns) =>
                                                ns.stationName === s.stationName
                                            )
                                          );
                                        setShowStation(allStationsSelected); // If all stations are selected, mark showStation as true
                                        return newStations;
                                      } else {
                                        const newStations = prevStations.filter(
                                          (s) =>
                                            s.stationName !==
                                            station.stationName
                                        );
                                        setShowStation(false); // Since one station is unchecked, uncheck showStation
                                        return newStations;
                                      }
                                    });
                                  }}
                                  className="mr-2"
                                />
                                {station.stationName.length > 12
                                  ? `${station.stationName.slice(0, 12)}...`
                                  : station.stationName}
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent onClick for the parent <li>
                                    handleDeleteStation(station.stationName);
                                  }}
                                  className="ml-auto text-red-500"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path d="M3 6h18" />
                                    <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                    <path d="M10 11v6" />
                                    <path d="M14 11v6" />
                                    <path d="M5 6l1 14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-14" />
                                  </svg>
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}

                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={showZone}
                            onChange={(e) => {
                              ZonesHandler(e);
                              setSelectedZone(e.target.checked ? zones : []); // Select all zones if checked, deselect all if unchecked
                            }}
                          />
                          <div
                            className="flex gap-[0.5rem] w-[100%]"
                            onClick={() =>
                              setShowIndividualZone(!showIndividualZone)
                            }
                          >
                            <img
                              src="svgs/zones.svg"
                              alt="Zones"
                              className="w-[22px] h-[22px]"
                            />
                            <span>Zones</span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualZone(!showIndividualZone)
                          }
                        >
                          {showIndividualZone ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualZone && zones && zones.length > 0 && (
                        <ul className="zone-list rounded-md pl-6 mr-2 mb-4">
                          {zones.map((zone, index) => (
                            <li
                              key={index}
                              className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                              onClick={() => {
                                setSelectedZone((prevZones) => {
                                  const isZoneSelected = prevZones.some(
                                    (z) => z.zonename === zone.zonename
                                  );
                                  let newZones;

                                  if (isZoneSelected) {
                                    newZones = prevZones.filter(
                                      (z) => z.zonename !== zone.zonename
                                    );
                                  } else {
                                    newZones = [...prevZones, zone];
                                  }

                                  const allZonesSelected =
                                    zones.length > 0 &&
                                    zones.every((z) =>
                                      newZones.some(
                                        (nz) => nz.zonename === z.zonename
                                      )
                                    );
                                  setShowZone(allZonesSelected); // If all zones are selected, mark showZone as true
                                  return newZones;
                                });
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  !!selectedZone.find(
                                    (z) => z.zonename === zone.zonename
                                  )
                                } // Check if the zone is in selectedZone
                                onChange={(e) => {
                                  e.stopPropagation();
                                  const isChecked = e.target.checked;
                                  setSelectedZone((prevZones) => {
                                    if (isChecked) {
                                      const newZones = [...prevZones, zone];
                                      const allZonesSelected =
                                        zones.length > 0 &&
                                        zones.every((z) =>
                                          newZones.some(
                                            (nz) => nz.zonename === z.zonename
                                          )
                                        );
                                      setShowZone(allZonesSelected); // If all zones are selected, mark showZone as true
                                      return newZones;
                                    } else {
                                      const newZones = prevZones.filter(
                                        (z) => z.zonename !== zone.zonename
                                      );
                                      setShowZone(false); // Since one zone is unchecked, uncheck showZone
                                      return newZones;
                                    }
                                  });
                                }}
                                className="mr-2"
                              />
                              {zone.zonename.length > 12
                                ? `${zone.zonename.slice(0, 12)}...`
                                : zone.zonename}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent onClick for the parent <li>
                                  handleDeleteZone(zone.zonename);
                                }}
                                className="ml-auto text-red-500"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                  <path d="M10 11v6" />
                                  <path d="M14 11v6" />
                                  <path d="M5 6l1 14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-14" />
                                </svg>
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                      <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem] w-[100%]">
                          <input
                            type="checkbox"
                            checked={
                              cartParkingData.length > 0 &&
                              JSON.parse(cartParkingData)
                                .filter((parking) => parking.bot_parking)
                                .every((parking) =>
                                  selectedParking.some(
                                    (s) => s.stationName === parking.stationName
                                  )
                                )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowParking(isChecked); // Set the showCartParking checkbox state
                              setSelectedParking(
                                isChecked ? JSON.parse(cartParkingData) : []
                              ); // Select all cart parking if checked, deselect all if unchecked
                            }}
                          />
                          <div
                            className="flex gap-[0.5rem] w-[100%]"
                            onClick={() =>
                              setShowIndividualParking(!showIndividualParking)
                            }
                          >
                            <img
                              src="svgs/stations.svg"
                              alt="Bot Parking"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Parking</span>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualParking(!showIndividualParking)
                          }
                        >
                          {showIndividualParking ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button>

                      {showIndividualParking &&
                        cartParkingData &&
                        JSON.parse(cartParkingData).filter(
                          (Parking) => Parking.bot_parking
                        ).length > 0 && (
                          <ul className="cart-parking-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(cartParkingData)
                              .filter((Parking) => Parking.bot_parking)
                              .map((Parking, index) => (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedParking((prevParkings) => {
                                      const isCartParkingSelected =
                                        prevParkings.some(
                                          (s) =>
                                            s.stationName ===
                                            Parking.stationName
                                        );
                                      let newParkings;
                                      if (isCartParkingSelected) {
                                        newParkings = prevParkings.filter(
                                          (s) =>
                                            s.stationName !==
                                            Parking.stationName
                                        );
                                      } else {
                                        newParkings = [
                                          ...prevParkings,
                                          Parking,
                                        ];
                                      }

                                      // Update the `showCartParking` state
                                      const allParkingsSelected =
                                        cartParkingData.length > 0 &&
                                        JSON.parse(cartParkingData).every((s) =>
                                          newParkings.some(
                                            (ns) =>
                                              ns.stationName === s.stationName
                                          )
                                        );
                                      setShowParking(allParkingsSelected); // If all cart parkings are selected, mark showCartParking as true
                                      return newParkings;
                                    });
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      !!selectedParking.find(
                                        (s) =>
                                          s.stationName === Parking.stationName
                                      )
                                    }
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedParking((prevParkings) => {
                                        if (isChecked) {
                                          const newParkings = [
                                            ...prevParkings,
                                            Parking,
                                          ];
                                          const allParkingsSelected =
                                            cartParkingData.length > 0 &&
                                            JSON.parse(cartParkingData).every(
                                              (s) =>
                                                newParkings.some(
                                                  (ns) =>
                                                    ns.stationName ===
                                                    s.stationName
                                                )
                                            );
                                          setShowParking(allParkingsSelected); // If all cart parkings are selected, mark showCartParking as true
                                          return newParkings;
                                        } else {
                                          const newParkings =
                                            prevParkings.filter(
                                              (s) =>
                                                s.stationName !==
                                                Parking.stationName
                                            );
                                          setShowParking(false); // Since one cart parking is unchecked, uncheck showCartParking
                                          return newParkings;
                                        }
                                      });
                                    }}
                                    className="mr-2"
                                  />
                                  {Parking.stationName.length > 12
                                    ? `${Parking.stationName.slice(0, 12)}...`
                                    : Parking.stationName}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteParking(Parking.stationName);
                                    }}
                                    className="ml-auto text-red-500"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M3 6h18" />
                                      <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      <path d="M10 11v6" />
                                      <path d="M14 11v6" />
                                      <path d="M5 6l1 14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-14" />
                                    </svg>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        )}
                      {/* <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                        <div className="flex gap-[0.5rem]">
                          <input
                            type="checkbox"
                            checked={
                              cartParkingData.length > 0 &&
                              JSON.parse(cartParkingData)
                                .filter(
                                  (cartparking) =>
                                    cartparking.cart_parking_full ||
                                    cartparking.cart_parking_empty
                                )
                                .every((cartparking) =>
                                  selectedCartParking.some(
                                    (s) =>
                                      s.stationName === cartparking.stationName
                                  )
                                )
                            }
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setShowCartParking(isChecked); // Set the showCartParking checkbox state
                              setSelectedCartParking(
                                isChecked ? JSON.parse(cartParkingData) : []
                              ); // Select all cart parking if checked, deselect all if unchecked
                            }}
                          />
                          <img
                            src="svgs/stations.svg"
                            alt="Cart Parking"
                            className="w-[22px] h-[22px] "
                          />
                          <span>Cart Parking</span>
                        </div>
                        <button
                          onClick={() =>
                            setShowIndividualCartParking(
                              !showIndividualCartParking
                            )
                          }
                        >
                          {showIndividualCartParking ? (
                            <img
                              src="svgs/arrow_up.svg"
                              alt="Toggle"
                              className="w-5.5 h-5.5"
                            />
                          ) : (
                            <img
                              src="svgs/arrow_right.svg"
                              alt="Arrow"
                              className="w-5.5 h-5.5 cursor-pointer"
                            />
                          )}
                        </button>
                      </button> */}

                      {showIndividualCartParking &&
                        cartParkingData &&
                        JSON.parse(cartParkingData).filter(
                          (cartParking) =>
                            cartParking.cart_parking_full ||
                            cartParking.cart_parking_empty
                        ).length > 0 && (
                          <ul className="cart-parking-list rounded-md pl-6 mr-2 mb-4">
                            {JSON.parse(cartParkingData)
                              .filter(
                                (cartParking) =>
                                  cartParking.cart_parking_full ||
                                  cartParking.cart_parking_empty
                              )
                              .map((cartParking, index) => (
                                <li
                                  key={index}
                                  className="text-white py-2 pl-2 hover:bg-[#FFFFFF1F] rounded-sm flex items-center"
                                  onClick={() => {
                                    setSelectedCartParking(
                                      (prevCartParkings) => {
                                        const isCartParkingSelected =
                                          prevCartParkings.some(
                                            (s) =>
                                              s.stationName ===
                                              cartParking.stationName
                                          );
                                        let newCartParkings;
                                        if (isCartParkingSelected) {
                                          newCartParkings =
                                            prevCartParkings.filter(
                                              (s) =>
                                                s.stationName !==
                                                cartParking.stationName
                                            );
                                        } else {
                                          newCartParkings = [
                                            ...prevCartParkings,
                                            cartParking,
                                          ];
                                        }

                                        // Update the `showCartParking` state
                                        const allCartParkingsSelected =
                                          cartParkingData.length > 0 &&
                                          JSON.parse(cartParkingData).every(
                                            (s) =>
                                              newCartParkings.some(
                                                (ns) =>
                                                  ns.stationName ===
                                                  s.stationName
                                              )
                                          );
                                        setShowCartParking(
                                          allCartParkingsSelected
                                        ); // If all cart parkings are selected, mark showCartParking as true
                                        return newCartParkings;
                                      }
                                    );
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      !!selectedCartParking.find(
                                        (s) =>
                                          s.stationName ===
                                          cartParking.stationName
                                      )
                                    } // Check if the cart parking is in selectedCartParking
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const isChecked = e.target.checked;
                                      setSelectedCartParking(
                                        (prevCartParkings) => {
                                          if (isChecked) {
                                            const newCartParkings = [
                                              ...prevCartParkings,
                                              cartParking,
                                            ];
                                            const allCartParkingsSelected =
                                              cartParkingData.length > 0 &&
                                              JSON.parse(cartParkingData).every(
                                                (s) =>
                                                  newCartParkings.some(
                                                    (ns) =>
                                                      ns.stationName ===
                                                      s.stationName
                                                  )
                                              );
                                            setShowCartParking(
                                              allCartParkingsSelected
                                            ); // If all cart parkings are selected, mark showCartParking as true
                                            return newCartParkings;
                                          } else {
                                            const newCartParkings =
                                              prevCartParkings.filter(
                                                (s) =>
                                                  s.stationName !==
                                                  cartParking.stationName
                                              );
                                            setShowCartParking(false); // Since one cart parking is unchecked, uncheck showCartParking
                                            return newCartParkings;
                                          }
                                        }
                                      );
                                    }}
                                    className="mr-2"
                                  />
                                  {cartParking.stationName.length > 12
                                    ? `${cartParking.stationName.slice(0, 12)}...`
                                    : cartParking.stationName}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent onClick for the parent <li>
                                      handleDeleteCartParking(
                                        cartParking.stationName
                                      );
                                    }}
                                    className="ml-auto text-red-500"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path d="M3 6h18" />
                                      <path d="M8 6V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                      <path d="M10 11v6" />
                                      <path d="M14 11v6" />
                                      <path d="M5 6l1 14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-14" />
                                    </svg>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        )}
                    </div>
                  </>
                )}
              </div>
              <div>
                <span
                  className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer"
                  onClick={() => setRecordList(!recordList)}
                >
                  <div className="flex justify-center items-center gap-2 p-1">
                    <img
                      src="svgs/record.svg"
                      alt="Record"
                      className="w-5.5 h-5.5"
                    />
                    <span>Record</span>
                  </div>
                  <span>
                    {recordList ? (
                      <img
                        src="svgs/arrow_up.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    ) : (
                      <img
                        src="svgs/arrow_down.svg"
                        alt="Toggle"
                        className="w-5.5 h-5.5"
                      />
                    )}
                  </span>
                </span>
                {recordList && (
                  <div className=" ml-7 mb-3 list-none">
                    <button
                      onClick={PathDrawHandler}
                      className={`flex hover:bg-[#FFFFFF1F] ${
                        ToRecord && `bg-[#FFFFFF1F] border rounded-md`
                      } w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem] ">
                        <img
                          src="svgs/path_d.svg"
                          alt="Path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (draw)</span>
                      </div>
                      {ToRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>
                    {ToRecord && (
                      <div className="pl-4">
                        <button
                          onClick={pathRecordHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>

                        <button
                          onClick={AIpathHandler}
                          className={`flex w-full rounded-md p-2 justify-between items-center cursor-pointer text-sm ${
                            AIpath ? 'bg-[#FFFFFF1F]' : 'hover:bg-[#FFFFFF1F]'
                          }`}
                        >
                          <div className="flex gap-[0.5rem]">
                            <img
                              src="svgs/path_t.svg"
                              alt="path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>AI Smootheing</span>
                          </div>
                        </button>
                        <button
                          onClick={clearPathHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    <button
                      onClick={TracePathHandler}
                      className={`flex  hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer ${
                        tracePath && `bg-[#FFFFFF1F] border rounded-md`
                      }`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Path (Trace)</span>
                      </div>
                      {tracePath ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>

                    {tracePath && (
                      <div className="pl-4">
                        <button
                          onClick={TracePathSaveHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={AIpathHandler}
                          className={`flex w-full rounded-md p-2 justify-between items-center cursor-pointer text-sm ${
                            AIpath ? 'bg-[#FFFFFF1F]' : 'hover:bg-[#FFFFFF1F]'
                          }`}
                        >
                          <div className="flex gap-[0.5rem]">
                            <img
                              src="svgs/path_t.svg"
                              alt="path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>AI Smootheing</span>
                          </div>
                        </button>
                        <button
                          onClick={clearTracedPathHandler
                          }
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    <button
                      onClick={handleParkingSpaceRecord}
                      className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between cursor-pointer"
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Parking Space</span>
                      </div>
                      {startParkingSpaceRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>
                    {startParkingSpaceRecord && (
                      <div className="pl-4">
                        <button
                          onClick={handleSaveBotParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        {/* add clear parking space (bot) button */}
                        <button
                          onClick={handleClearBotParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}

                    <button
                      onClick={recordStationHandler}
                      className={`flex ${
                        showStationRecord && `bg-[#FFFFFF1F]`
                      } hover:bg-[#FFFFFF1F]  w-full rounded-md p-2 justify-between items-center cursor-pointer`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_d.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (draw)</span>
                      </div>
                      {showStationRecord ? (
                        <img
                          src="svgs/arrow_up.svg"
                          alt="Toggle"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5 cursor-pointer"
                        />
                      )}
                    </button>
                    {showStationRecord && (
                      <ul className="path-list duration-100 rounded-md pl-6  mb-4">
                        <li
                          onClick={clearStationHandler}
                          className={`text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md ${stationRecord}`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </li>
                        <li
                          onClick={stationSave}
                          className="text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md"
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </li>
                      </ul>
                    )}

                    <button
                      onClick={TraceStationHandler}
                      className={`flex  hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer ${
                        traceStation && `bg-[#FFFFFF1F] border rounded-md`
                      }`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (Tag)</span>
                      </div>
                      {traceStation ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>

                    {traceStation && (
                      <div className="pl-4">
                        <button
                          onClick={TraceStationSaveHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={clearTracedStationHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}

                    <button
                      onClick={AprilTagsHandler}
                      className={`flex  hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer ${
                        AprilTags && `bg-[#FFFFFF1F] border rounded-md`
                      }`}
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="April Tags"
                          className="w-[22px] h-[22px] "
                        />
                        <span>April Tags</span>
                      </div>
                      {AprilTags ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button>

                    {AprilTags && (
                      <div className="pl-4">
                        <button
                          onClick={AprilTagsSaveHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="April Tags"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={clearAprilTagsHandler}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="April Tags"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}

                    {/* <button
                      onClick={handleCartParkingSpaceRecord}
                      className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between cursor-pointer"
                    >
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/path_w.svg"
                          alt="path"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Cart Parking Space</span>
                      </div>
                      {startCartParkingSpaceRecord ? (
                        <img
                          src="svgs/arrow_down.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      ) : (
                        <img
                          src="svgs/arrow_right.svg"
                          alt="Arrow"
                          className="w-5.5 h-5.5"
                        />
                      )}
                    </button> */}
                    {startCartParkingSpaceRecord && (
                      <div className="pl-4">
                        <button
                          onClick={handleSaveCartParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </button>
                        <button
                          onClick={handleClearCartParkingSpace}
                          className={`flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between text-sm items-center cursor-pointer`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </button>
                      </div>
                    )}
                    {/* <button className="flex hover:bg-[#FFFFFF1F] w-full rounded-md p-2 justify-between items-center cursor-pointer">
                      <div className="flex gap-[0.5rem]">
                        <img
                          src="svgs/station_t.svg"
                          alt="station"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Station (tag)</span>
                      </div>
                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button> */}
                    <button
                      className={`flex hover:bg-[#FFFFFF1F]  w-full ${
                        CreateZone
                          ? 'bg-[#FFFFFF1F] border rounded-md'
                          : 'bg-none'
                      } rounded-md p-2 justify-between items-center cursor-pointer `}
                      onClick={ZoneClickHandler}
                    >
                      <div className="flex gap-[0.5rem] w-[100%]">
                        <img
                          src="svgs/zone.svg"
                          alt="zone"
                          className="w-[22px] h-[22px] "
                        />
                        <span>Zone</span>
                      </div>

                      <img
                        src="svgs/arrow_right.svg"
                        alt="Arrow"
                        className="w-5.5 h-5.5"
                      />
                    </button>
                    {CreateZone && (
                      <ul className="path-list duration-100 rounded-md pl-6  mb-4">
                        <li
                          onClick={() => {
                            setZonePointArray({
                              x: [],
                              y: [],
                            });
                          }}
                          className={`text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md ${stationRecord}`}
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Clear</span>
                          </div>
                        </li>
                        <li
                          onClick={ZoneSaveHandler}
                          className="text-white py-1 pl-2 hover:bg-[#FFFFFF1F] rounded-md"
                        >
                          <div className="flex gap-[0.5rem] ">
                            <img
                              src="svgs/path_d.svg"
                              alt="Path"
                              className="w-[22px] h-[22px] "
                            />
                            <span>Save</span>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
              <button
                onClick={() => RemoteControlHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-5.5 h-5.5"
                />
                <span>Remote control</span>
              </button>
              <button
                onClick={() => UploadTaskHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-5.5 h-5.5"
                />
                <span>Upload Task</span>
              </button>
              <button
                onClick={() => AnalyticsHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-5.5"
                />
                <span>Analytics</span>
              </button>
              <button
                onClick={()=>{
                  navigate("/botStatus")
                }}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-5.5 h-5.5"
                />
                <span>bot Status</span>
              </button>
              <button
                onClick={() => TagsHandler()}
                className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md pl-3 px-2 py-3 gap-2 cursor-pointer  items-center"
              >
                <img src="svgs/bots.svg" alt="Tags" className="w-5.5 h-5.5" />
                <span>Tags (Management)</span>
              </button>
              <div className=" text-[0.7rem] text-[#81B3C7] mt-8 mb-2 ml-3">
                ADMIN
              </div>
              <button className="flex hover:bg-[#FFFFFF1F] w-[100%] rounded-md  gap-2 cursor-pointer p-3" onClick={()=>navigate("/settings")}>
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-5.5 h-5.5"
                />
                <span>Settings</span>
              </button>
            </div>
          ) : (
            // UI when SideBar is Closed

            <div className=" text-sm flex flex-col items-center text-white dropdown-container h-[55%] gap-8 overflow-y-auto">
              <button
    onClick={() => {
      if(!isDejitterActive)
      {
       setIsDejitterActive(true);
       navigate('/settings');
      }
      else
      setIsDejitterActive(false);
     }}
    className={`relative w-12 h-5 flex mt-10  items-center rounded-full p-1 transition-all duration-300 ease-in-out 
      ${isDejitterActive ? 'bg-[#4CAF50]' : 'bg-gray-500'}`}
  >
    <div
      className={`absolute w-3 h-3 bg-white rounded-full shadow-md transform transition-all duration-300 
        ${isDejitterActive ? 'translate-x-7' : 'translate-x-1'}`}
    ></div>
  </button>
              <button
                className="flex  rounded-md  justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setShowList(true);
                }}
              >
                <img
                  src="svgs/show.svg"
                  alt="Toggle"
                  className=" w-[20px] hover:bg-[#FFFFFF1F]"
                />
              </button>
              <button
                className="flex  rounded-md justify-center"
                onClick={() => {
                  setToggle(!toggle);
                  setRecordList(true);
                }}
              >
                <img
                  src="svgs/record.svg"
                  alt="Record"
                  className="w-[20px] hover:bg-[#FFFFFF1F]"
                />
              </button>
              <button
                className="flex  rounded-md  justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/remote_control.svg"
                  alt="Remote"
                  className="w-[20px]  hover:bg-[#FFFFFF1F]"
                />
              </button>
              <button
                className="flex  rounded-md justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/upload_task.svg"
                  alt="Upload"
                  className="w-[20px]  hover:bg-[#FFFFFF1F]"
                />
              </button>
              <button
                className="flex  rounded-md justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/analytics.svg"
                  alt="Analytics"
                  className="w-[20px]  hover:bg-[#FFFFFF1F]"
                />
              </button>
              <button
                className="flex  rounded-md justify-center"
                onClick={() => {
                  setToggle(!toggle);
                }}
              >
                <img
                  src="svgs/settings.svg"
                  alt="Settings"
                  className="w-[20px] mt-7 hover:bg-[#FFFFFF1F] "
                />
              </button>
            </div>
          )}
          <div
            className={` flex flex-col ${!toggle &&`h-fit`} self-baseline justify-center gap-[1vh]  w-full  text-white`}
          >
            <hr className="border-[#4290AF] mb-[2vh]  w-[90%] self-center "></hr>
            <div
              className={`profile flex gap-[0.5rem] px-4 items-center content-between  ${
                toggle && 'flex-col justify-center items-center gap-4'
              } `}
            >
              <img
                src={CompanyLogo}
                alt="Profile"
                className="w-12 h-12 rounded-full bg-white p-0.5"
              />
              {!toggle && (
                <div>
                  <div className="profile-info flex flex-col ">
                    <div className="font-sans text-l">{companyName}</div>
                    <div className=" font-sans max-w-28 text-left text-xs text-custom-blue">
                      {userEmail.length > 20
                        ? userEmail.slice(0, 10) + '....' + userEmail.slice(-10)
                        : userEmail}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="theme-toggle flex justify-center">
              {
                <button onClick={handleThemeToggle} className=" rounded-md">
                  {toggle === false ? (
                    theme === 'dark' ? (
                      <img
                        src="svgs/dark.svg"
                        alt="Dark Mode"
                        className="w-full img-full"
                      />
                    ) : (
                      <img
                        src="svgs/light.svg"
                        alt="Light Mode"
                        className="w-full img-full"
                      />
                    )
                  ) : theme === 'dark' ? (
                    <img
                      src="svgs/smallLDModenew.svg"
                      alt="Dark Mode"
                      className="w-[200%]"
                    />
                  ) : (
                    <img
                      src="svgs/smallLDMode.svg"
                      alt="Light Mode"
                      className="w-full"
                    />
                  )}
                </button>
              }
            </div>
            <button
              className={`w-[90%] self-center flex items-center justify-center gap-2 py-1 text-md font-semibold 
             ${theme == 'light' ? 'bg-[#286e89]' : 'bg-[#22353D]'} } text-white rounded-full shadow-md transition-all 
             hover:bg-[#347B96] hover:shadow-lg hover:scale-105 `}
              onClick={handleLogout}
            >
              <img src="svgs/logout.svg" alt="Logout" className="h-5" />
              {!toggle && <span>Logout</span>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
