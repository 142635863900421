import React from 'react';

const PolicyPage = () => {
  return (
    <div>
      <iframe
        src="DrobotCommandApp__rivacy_policy_and_terms_and_conditions.html"
        width="100%"
        height="725px"
        title="Policy Page"
      />
    </div>
  );
};

export default PolicyPage;
