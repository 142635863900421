import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';

const BarChartBot = ({ filteredTasks }) => {
  // Define unique colors for each bot
  const botColors = ['#A0E3D3', '#C7B5E1', '#4A7C59'];

  // Aggregate data for each bot
  const botMetrics = useMemo(() => {
    const botData = {};

    filteredTasks.forEach((task) => {
      const bot = task.task_log.assigned_bot; // Extract the bot assignment

      if (!bot) return; // Skip tasks that don't have an assigned bot

      // Initialize bot data if not already present
      if (!botData[bot]) {
        botData[bot] = {
          jobs: 0,
          hours: 0,
          miles: 0,
        };
      }

      // Increment jobs (tasks assigned to the bot)
      botData[bot].jobs++;

      // Calculate and add hours
      let startTime = 0,
        endTime = 0;
      if (task.task_log.task_start_time !== '') {
        startTime = parseInt(task.task_log.task_start_time, 10);
      }
      if (task.task_log.task_end_time !== '') {
        endTime = parseInt(task.task_log.task_end_time, 10);
      }
      if (startTime && endTime && endTime > startTime) {
        botData[bot].hours += Math.abs(endTime - startTime) / 3600; // Convert to hours
      }

      // Calculate and add miles
      if (task.task_detail?.task_station?.length) {
        botData[bot].miles += parseFloat(task.task_detail.task_station.length);
      }
    });

    // Convert bot data to an array for rendering
    return Object.keys(botData).map((bot, index) => ({
      bot,
      jobs: botData[bot].jobs,
      hours: botData[bot].hours,
      miles: botData[bot].miles,
      color: botColors[index % botColors.length], // Assign unique color to each bot
    }));
  }, [filteredTasks]);

  // Prepare data for Jobs, Hours, and Miles charts
  const jobsData = botMetrics.map((botMetric) => ({
    name: botMetric.bot,
    value: botMetric.jobs,
    color: botMetric.color,
  }));

  const hoursData = botMetrics.map((botMetric) => ({
    name: botMetric.bot,
    value: botMetric.hours,
    color: botMetric.color,
  }));

  const milesData = botMetrics.map((botMetric) => ({
    name: botMetric.bot,
    value: botMetric.miles,
    color: botMetric.color,
  }));

  // Custom Tooltip to display the bot's metric value on hover
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;
      return (
        <div style={tooltipStyle}>
          <p>{`${name}: ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  // Inline styles for tooltip
  const tooltipStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    padding: '8px',
    borderRadius: '4px',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      {/* Chart 1: Jobs */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
        <h3 style={cardTitleStyle}>Jobs</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={jobsData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value">
              {jobsData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Chart 2: Hours */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
        <h3 style={cardTitleStyle}>Seconds</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={hoursData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value">
              {hoursData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Chart 3: Miles */}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
        <h3 style={cardTitleStyle}>Miles</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={milesData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value">
              {milesData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const cardTitleStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '16px',
};

export default BarChartBot;
