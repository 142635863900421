const isPointInPolygon = (x, y, polygon) => {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0],
      yi = polygon[i][1];
    const xj = polygon[j][0],
      yj = polygon[j][1];
    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};
const polySort = (points) => {
  if (
    !points ||
    !Array.isArray(points.x) ||
    !Array.isArray(points.y) ||
    points.x.length === 0 ||
    points.y.length === 0
  ) {
    console.error('Invalid points object');
    return;
  }

  let centre = [
    points.x.reduce((sum, x) => sum + x, 0) / points.x.length,
    points.y.reduce((sum, y) => sum + y, 0) / points.y.length,
  ];

  let combinedPoints = points.x.map((x, i) => [x, points.y[i]]);
  for (let point of combinedPoints) point.push(...squaredPolar(point, centre));
  combinedPoints.sort((a, b) => a[2] - b[2] || a[3] - b[3]);
  for (let point of combinedPoints) point.length -= 2;

  points.x = combinedPoints.map((point) => point[0]);
  points.y = combinedPoints.map((point) => point[1]);
};
const squaredPolar = (point, centre) => {
  return [
    Math.atan2(point[1] - centre[1], point[0] - centre[0]),
    (point[0] - centre[0]) ** 2 + (point[1] - centre[1]) ** 2, // Square of distance
  ];
};

export { isPointInPolygon, polySort, squaredPolar };
