import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="font-sans">
      {/* Navbar */}
      <nav className="w-full bg-[#015d81] py-4 px-8">
        <div className="max-w-6xl mx-auto flex items-center justify-between">
          {/* Logo Section */}
          <a href="/" className="flex items-center space-x-3 text-white">
            <img
              src="svgs/drobot_logo.svg"
              alt="Drobot Logo"
              className="h-100 w-100"
            />
          </a>

          {/* Login Button */}
          <a href="/login">
            <button className="text-white font-sans text-lg border-white border font-semibold bg-none px-6 py-1 rounded-lg hover:bg-white hover:text-[#015d81] hover:scale-105 transition-colors duration-300 ">
              Login
            </button>
          </a>
        </div>
      </nav>
      {/* Hero Section */}
      <div
        className="h-[90vh] bg-[#015d81] flex flex-col justify-center items-center text-center text-white"
        data-aos="fade-up"
      >
        <img
          src="svgs/drobot_logo.svg"
          className="h-[8rem] mb-6"
          alt="Drobot"
        />
        <div className="text-4xl font-bold leading-tight">
          Collaborative Mobile Robots
          <br />
          <span className="text-2xl font-medium">
            Working with and for humans<sup>TM</sup>
          </span>
        </div>
      </div>

      {/* About Section */}
      <div
        className="min-h-[100vh] bg-gray-100 flex items-center justify-center px-6 py-16"
        data-aos="fade-up"
      >
        <div className="max-w-4xl text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8">
            About Drobot
          </h2>
          <p className="text-2xl text-gray-600 leading-relaxed mb-4">
            Drobot develops Collaborative Mobile Robots that are designed to
            work with and for people. Our robots are able to operate in tight,
            cluttered and dynamic environments, such as Manufacturing job shops,
            where they will find, fetch, and transfer things, not just in a
            pre-scheduled manner, but also responding to ad hoc requests by
            shopfloor Operators and Technicians. Our robots don’t just navigate
            between fixed workstations, but also fetch and transfer directly
            between people who may be moving around the plant. All made super
            easy, using smartphone, tablet, or smartwatch – as well as through
            natural language and intuitive gestures.
          </p>
          <p className="text-2xl text-gray-600 leading-relaxed">
            We are currently in stealth mode, Piloting at a few first customers
            and plan to bring our solution to the wider market over the next 12
            months.
          </p>
        </div>
      </div>

      {/* Leadership Team Section */}
      <div className="py-16 bg-white">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-4xl font-bold text-gray-800">Leadership Team</h2>
        </div>
        <div className="flex flex-col gap-8 justify-center items-center px-4 md:px-10">
          {/* Team Member 1 */}
          <div
            className="flex flex-col sm:flex-row gap-6 w-full lg:w-2/3 bg-gradient-to-r from-white to-[#015d81] rounded-lg shadow-lg p-6 md:p-10 items-center"
            data-aos="fade-left"
          >
            <img
              src="images/STJN.png"
              alt="Stijn Van de Velde"
              className="h-32 w-32 sm:h-40 sm:w-40 rounded-full object-cover shadow-lg"
            />
            <div className="text-center sm:text-left mt-4 sm:mt-0">
              <h3 className="text-3xl sm:text-4xl font-bold text-white">
                Stijn Van de Velde
              </h3>
              <p className="text-xl sm:text-2xl text-white">Co-Founder & CEO</p>
            </div>
          </div>

          {/* Team Member 2 */}
          <div
            className="flex flex-col sm:flex-row-reverse gap-6 w-full lg:w-2/3 bg-gradient-to-r from-[#015d81] to-white rounded-lg shadow-lg p-6 md:p-10 items-center"
            data-aos="fade-right"
          >
            <img
              src="images/Ankit.png"
              alt="Ankit Verma"
              className="h-32 w-32 sm:h-40 sm:w-40 rounded-full object-cover shadow-lg"
            />
            <div className="text-center sm:text-left mt-4 sm:mt-0">
              <h3 className="text-3xl sm:text-4xl font-bold text-white">
                Ankit Verma
              </h3>
              <p className="text-xl sm:text-2xl text-white">Co-Founder & CTO</p>
            </div>
          </div>

          {/* Team Member 3 */}
          <div
            className="flex flex-col sm:flex-row gap-6 w-full lg:w-2/3 bg-gradient-to-r from-white to-[#015d81] rounded-lg shadow-lg p-6 md:p-10 items-center"
            data-aos="fade-left"
          >
            <img
              src="images/Joseph.png"
              alt="Joseph Etris"
              className="h-32 w-32 sm:h-40 sm:w-40 rounded-full object-cover shadow-lg"
            />
            <div className="text-center sm:text-left mt-4 sm:mt-0">
              <h3 className="text-3xl sm:text-4xl font-bold text-white">
                Joseph Etris
              </h3>
              <p className="text-xl sm:text-2xl text-white">Co-Founder & COO</p>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="text-white py-20 bg-[#015d81]" data-aos="fade-up">
        <div className="text-center mx-auto w-full max-w-7xl">
          <div className="flex flex-col md:flex-row justify-around items-start md:items-center space-y-12 md:space-y-0 px-10">
            {/* Logo Section */}
            <div>
              <img
                src="svgs/drobot_logo.svg"
                alt="Drobot Logo"
                className="h-20 w-44"
              />
            </div>

            {/* Address Section */}
            <div className="text-left md:text-center max-w-xs">
              <h3 className="text-xl font-semibold">Address:</h3>
              <p className="text-lg mt-2 leading-relaxed">
                Drobot Inc.
                <br />
                1200 Woodruff Rd., Suite C-40
                <br />
                Greenville, SC 29607, USA
              </p>
            </div>
            {/* Contact Section */}
            <div className="text-left md:text-center max-w-xs">
              <h3 className="text-xl font-semibold">Contact:</h3>
              <p className="text-lg mt-2 leading-relaxed">
                Stijn Van de Velde, CEO
                <br />
                <span>
                  Email:{' '}
                  <a
                    href="mailto:stijn@drobot.ai"
                    className="text-blue-400 hover:text-blue-300 underline"
                  >
                    stijn@drobot.ai
                  </a>
                </span>
                <br />
                Cell: +1 (864) 525 5458
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
