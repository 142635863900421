import React, { useState } from 'react';
import SideBar from './SideBar.js';
import { useRecordPath } from '../Context/Context.js';
import { FaArrowLeft, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function Settings() {
    const navigate = useNavigate();
    const {
        DeJitterParam, setDeJitterParam,isDejitterActive,lastDeJitterValue, setLastDeJitterValue
    }=useRecordPath();
  return (
    <div className="flex">
      <SideBar />
      <div className="p-8 text-gray-900 bg-gray-100 w-full min-h-screen h-full">
        {/* Page Heading */}
        <h1 className="text-3xl font-bold text-[#004b66] mb-8 border-b-2 pb-2">Settings</h1>
        <button
                  onClick={() => navigate("/home")}
                  className="flex items-center gap-2 bg-[#133846] hover:scale-105 hover:bg-gray-700 text-white px-4 py-2 rounded-lg shadow-md transition duration-300"
                >
                  <FaArrowLeft /> Back
                </button>
        

        <div className="bg-white p-6 rounded-lg shadow-lg w-full mx-auto">
          {/* De-Jitter Parameter Label */}
          <label className="block text-lg font-semibold text-gray-800 mb-4">
            De-Jitter Parameter: <span className="text-[#004b66]">{DeJitterParam/200}</span>
          </label>
          
          {/* Slider Input */}
          <input
  type="range"
  min="1"
  max="10"
  value={DeJitterParam/200}
  onChange={(e) => {
    const newValue = parseInt(e.target.value*200, 10);
    setLastDeJitterValue(newValue);
    console.log(newValue);
     // Always keep track of the last valid value
    if (isDejitterActive) {
      setDeJitterParam(newValue);
    }
  }}
  className="w-full cursor-pointer appearance-none h-2 bg-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#004b66]"
/>

          
          {/* Min - Max Labels */}
          <div className="flex justify-between text-sm text-gray-600 mt-2">
            <span>1</span>
            <span>10</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
