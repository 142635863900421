import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const TaskSummary = ({ filteredTasks }) => {
  const scheduledTasks = filteredTasks.filter(
    (task) => task.task_detail.scheduled !== ''
  ).length;
  const userRequestedTasks = filteredTasks.filter(
    (task) => task.task_detail.scheduled === ''
  ).length;
  const fillerTasks = filteredTasks.filter(
    (task) => task.type === 'filler'
  ).length;

  // Calculate time and distance for each task type
  const calculateMetrics = (filteredTasks) => {
    let totalTimeDriven = 0;
    let totalDistanceDriven = 0;

    filteredTasks.forEach((task) => {
      let startTime = 0,
        endTime = 0;

      if (task.task_log.task_start_time !== '') {
        startTime = parseInt(task.task_log.task_start_time, 10);
      }
      if (task.task_log.task_end_time !== '') {
        endTime = parseInt(task.task_log.task_end_time, 10);
      }

      if (
        !isNaN(startTime) &&
        !isNaN(endTime) &&
        task.task_log.task_start_time !== '' &&
        task.task_log.task_end_time !== '' &&
        endTime !== 0 &&
        startTime !== 0 &&
        endTime > startTime &&
        Math.abs(endTime - startTime) < 10000
      ) {
        totalTimeDriven += Math.abs(endTime - startTime);
      }

      if (task.task_detail?.task_station?.length) {
        totalDistanceDriven += parseFloat(task.task_detail.task_station.length);
      }
    });

    return { totalTimeDriven, totalDistanceDriven };
  };

  const scheduledMetrics = calculateMetrics(
    filteredTasks.filter((task) => task.task_detail.scheduled !== '')
  );
  const userRequestedMetrics = calculateMetrics(
    filteredTasks.filter((task) => task.task_detail.scheduled === '')
  );
  const fillerMetrics = calculateMetrics(
    filteredTasks.filter((task) => task.task_detail.task_type === 'filler')
  );

  // Prepare data for rendering
  const data = [
    {
      name: 'Scheduled',
      value: scheduledTasks,
      distance: scheduledMetrics.totalDistanceDriven,
      time: scheduledMetrics.totalTimeDriven,
      color: '#4A7C59',
    },
    {
      name: 'User requested',
      value: userRequestedTasks,
      distance: userRequestedMetrics.totalDistanceDriven,
      time: userRequestedMetrics.totalTimeDriven,
      color: '#A0E3D3',
    },
    {
      name: 'Filler',
      value: fillerTasks,
      distance: fillerMetrics.totalDistanceDriven,
      time: fillerMetrics.totalTimeDriven,
      color: '#C7B5E1',
    },
  ];

  // Process filteredTasks data to generate summary statistics

  return (
    <div style={containerStyle}>
      <h2 style={cardTitleStyle}>Total Tasks Performed by Bots:</h2>
      <div style={chartAndListContainerStyle}>
        {/* Chart fills the upper half using ResponsiveContainer */}
        <div style={fullWidthChartStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={150} height={150}>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                innerRadius={100}
                fill="#8884d8"
                paddingAngle={0}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div style={taskDetailsStyle}>
          {data.map((item, index) => (
            <div key={index} style={taskItemStyle}>
              <div style={headerStyle}>
                <span
                  style={{ ...colorBoxStyle, backgroundColor: item.color }}
                ></span>
              </div>
              <div style={headerStyle}>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#374151',
                  }}
                >
                  {item.name}
                </span>
                <p style={taskValueStyle}>
                  {item.value} tasks &nbsp;&nbsp;{' '}
                  {(item.time / 3600).toFixed(2)} hours &nbsp;&nbsp;
                  &nbsp;&nbsp;
                  {item.distance.toFixed(2)} miles
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// ... (styles remain unchanged)

// Inline styles
// const cardTitleStyle = {
//   fontSize: '1.2rem',
//   fontWeight: 'bold',
//   marginBottom: '16px',
// };

const containerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const cardTitleStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '16px',
};

const chartAndListContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  // alignItems: "center",
  width: '100%',
  height: '600px', // Adjusted to fill half for chart, half for list
};

const fullWidthChartStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '125%',
};

const taskDetailsStyle = {
  marginLeft: '24px',
  display: 'flex',
  alignItems: 'left',
  flexDirection: 'column',
};

const taskItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '8px',
  backgroundColor: '#F3F4F6',
  borderRadius: '10px',
};

const colorBoxStyle = {
  display: 'inline-block',
  width: '4px',
  height: '36px',
  marginRight: '8px',
  marginLeft: '8px',
  borderRadius: '2px',
};

const headerStyle = {
  display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '4px',
  marginTop: '4px',
};

const taskInfoStyle = {
  fontSize: '14px',
  lineHeight: '1.5',
  color: '#6B7280',
};

const taskValueStyle = {
  fontWeight: '500',
  fontSize: '14px',
  color: '#111827',
};
export default TaskSummary;
