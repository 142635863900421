export default function convertToReal(x, y) {
  const companydata = JSON.parse(window.sessionStorage.getItem('companyData'));
  const xFlipped = companydata.map_details.x_flip;
  const yFlipped = companydata.map_details.y_flip;
  const scaleFactor = companydata.map_details.scale;
  const originX = -companydata.map_details.origin_x / scaleFactor;
  const originY = companydata.map_details.origin_y / scaleFactor;
  const img = new Image();
  img.src = companydata.map_details.floor_plan_url;

  const convertedX =
    ((x / xFlipped + (xFlipped * img.naturalWidth) / 2 - xFlipped * originX) *
      scaleFactor) /
    10;
  const convertedY =
    ((y / yFlipped + (yFlipped * img.naturalHeight) / 2 - yFlipped * originY) *
      scaleFactor) /
    10;

  return { convertedX, convertedY };
}
