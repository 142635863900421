import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { FaArrowLeft, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import SideBar from './SideBar.js';
const Tags = () => {
  const [activeTab, setActiveTab] = useState('People');
  const [tags, setTags] = useState([]);
  const [editTag, setEditTag] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const companyName = window.sessionStorage.getItem('companyName');
  const fetchTags = async () => {
    const response = await axios.get(
      `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${activeTab}`
    );
    console.log(response.data);

    setTags(response.data);
  };

  useEffect(() => {
    fetchTags();
  }, [activeTab]);

  const handleEdit = (tag) => {
    setEditTag(tag);
  };

  const handleDelete = async (tag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);

      // Construct the appropriate URL

      let requestUrl;
      if (activeTab === 'Route Transfer') {
        // Use the tagname directly for Route Transfer (it's the document ID)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/${companyName}/tags/${encodedCategory}/${tag.id}`;
      } else if (activeTab === 'Simple Transfer') {
        // Use the tagname for Simple Transfer (it's a field in the transfer_tags document)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}/${tag.tagname}`;
      } else if (activeTab === 'Materials') {
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}/${tag.name}`;
      } else {
        console.error('Unsupported category for deletion:', activeTab);
        return;
      }

      // Perform the DELETE request

      const response = await axios.delete(requestUrl);
      console.log('Delete response:', response.data);

      // Refresh the list of tags
      fetchTags();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleSave = async (updatedTag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);

      // Determine the correct URL for the category
      let requestUrl;
      if (activeTab === 'Route Transfer') {
        // Use the document ID for Route Transfer (editTag.id)
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}/${editTag.id}`;
      } else if (activeTab === 'Simple Transfer') {
        // Use the tagname for Simple Transfer
        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}/${editTag.tagname}`;
      } else if (activeTab === 'Materials') {
        // Use the tagid for Materials

        requestUrl = `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}/${editTag.name}`;
        console.log(requestUrl, 'url');
      } else {
        console.error('Unsupported category:', activeTab);
        return;
      }

      // Send the PUT request
      console.log('Saving to URL:', requestUrl, 'with data:', updatedTag); // Debug
      await axios.put(requestUrl, updatedTag);

      // Clear the edit form and refresh the tags
      setEditTag(null);
      fetchTags();
    } catch (error) {
      console.error('Error saving tag:', error);
    }
  };

  const handleAdd = async (newTag) => {
    try {
      const encodedCategory = encodeURIComponent(activeTab);

      // Adjust the payload based on the category
      let payload;

      if (activeTab === 'Simple Transfer') {
        // Payload for Simple Transfer
        payload = {
          tagname: newTag.tagname,
          home: newTag.home,
          destination: newTag.destination,
        };
      } else if (activeTab === 'Route Transfer') {
        // Payload for Route Transfer
        payload = {
          tagname: newTag.tagname,
          name: newTag.name,
          current_idx: newTag.current_idx,
          stations: newTag.route.split(',').map((station) => station.trim()), // Convert route string to array
        };
      } else if (activeTab === 'Materials') {
        payload = {
          tagid: newTag.tagid,
          name: newTag.tagname,
        };
      } else {
        console.error('Unsupported category:', activeTab);
        return;
      }

      // Make the POST request
      console.log(payload);
      await axios.post(
        `https://drobot-admin-v2-a2def93839bb.herokuapp.com/api/tags/${companyName}/${encodedCategory}`,
        payload
      );
      setShowAddForm(false);
      fetchTags(); // Refresh the tag list
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  return (
    <div className="flex">
      <SideBar />
      <div className="p-8 bg-[white] w-[100%] min-h-screen h-[100%] ">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 bg-[#133846] hover:scale-105 hover:bg-gray-700 text-white px-4 py-2 rounded-lg shadow-md transition duration-300"
        >
          <FaArrowLeft /> Back
        </button>

        <h1 className="text-4xl font-bold mt-4 text-[#015d81] ">
          Tag Management
        </h1>

        {/* Tab Navigation */}
        <div className="flex space-x-4 mt-4">
          {[
            'People',
            'Simple Transfer',
            'Route Transfer',
            'Materials',
          ].map((tab) => (
            <button
              key={tab}
              className={`py-2 px-6 rounded-lg text-lg font-semibold shadow-md transition-all duration-300 ${
                activeTab === tab
                  ? 'bg-[#133846] text-white'
                  : 'bg-gray-300 text-gray-800 hover:bg-gray-400'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="mt-6 bg-[#015d81] p-6 shadow-md rounded-lg">
          {activeTab !== 'People' && (
            <button
              className="flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg shadow-md transition duration-300"
              onClick={() => setShowAddForm(true)}
            >
              <FaPlus /> Add New Tag
            </button>
          )}

          {/* Table with Improved Styling */}
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full border border-gray-200 rounded-lg overflow-hidden">
              <thead className="bg-[white] text-xl text-[#015d81]">
                <tr>
                  {activeTab === 'Materials' ? (
                    <>
                      <th className="border border-gray-300 px-6 py-3">
                        Tag ID
                      </th>
                      <th className="border border-gray-300 px-6 py-3">Name</th>
                    </>
                  ) : (
                    Object.keys(tags[0] || {}).map((key) => (
                      <th
                        key={key}
                        className="border border-gray-300 px-6 py-3"
                      >
                        {key}
                      </th>
                    ))
                  )}
                  <th className="border border-gray-300 px-6 py-3">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tags.map((tag, index) => (
                  <tr
                    key={tag.id}
                    className={`border border-gray-300 ${
                      index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                    } hover:bg-gray-200 transition duration-300`}
                  >
                    {activeTab === 'Materials' ? (
                      <>
                        <td className="px-6 py-3 text-center">{tag.tagid}</td>
                        <td className="px-6 py-3 text-center">{tag.name}</td>
                      </>
                    ) : (
                      Object.values(tag).map((value, idx) => (
                        <td key={idx} className="px-6 py-3 text-center">
                          {typeof value === 'object' && value !== null
                            ? JSON.stringify(value)
                            : value}
                        </td>
                      ))
                    )}
                    <td className="px-6 py-3 text-center">
                      <button
                        onClick={() => setEditTag(tag)}
                        className="bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-1 rounded-lg shadow-md mr-2 transition duration-300"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(tag);
                        }}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded-lg shadow-md transition duration-300"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {editTag && (
            <EditForm
              tag={editTag}
              category={activeTab}
              onSave={handleSave}
              onClose={() => setEditTag(null)}
            />
          )}
          {showAddForm && (
            <AddForm
              category={activeTab}
              onSave={handleAdd}
              onClose={() => setShowAddForm(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const EditForm = ({ tag, category, onSave, onClose }) => {
  const [formData, setFormData] = useState({
    ...tag,
    ...(category === 'Route Transfer' && tag.stations
      ? { route: tag.stations.join(', ') } // Convert stations array to a string for editing
      : {}), // Do not include route for non-Route Transfer
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedTag =
      category === 'Route Transfer'
        ? {
            ...formData,
            stations: formData.route
              .split(',')
              .map((station) => station.trim()), // Convert route string back to an array
          }
        : formData; // For Simple Transfer and Materials, no extra processing

    onSave(updatedTag); // Save the updated tag
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 rounded-lg shadow-xl space-y-6 w-96 border border-gray-300"
      >
        {/* Modal Header */}
        <h2 className="text-lg font-semibold text-gray-800">Manage Transfer</h2>

        {/* Conditional Fields for Route Transfer */}
        {category === 'Route Transfer' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Job Name
              </label>
              <input
                type="text"
                value={formData.name || ''}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Current Index
              </label>
              <input
                type="number"
                value={formData.current_idx || ''}
                onChange={(e) =>
                  setFormData({ ...formData, current_idx: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Route
              </label>
              <input
                type="text"
                value={formData.route || ''}
                onChange={(e) =>
                  setFormData({ ...formData, route: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
                placeholder="Enter stations as comma-separated values"
              />
            </div>
          </>
        )}

        {/* Fields for Simple Transfer */}
        {category === 'Simple Transfer' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Home
              </label>
              <input
                type="text"
                value={formData.home || ''}
                onChange={(e) =>
                  setFormData({ ...formData, home: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Destination
              </label>
              <input
                type="text"
                value={formData.destination || ''}
                onChange={(e) =>
                  setFormData({ ...formData, destination: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
          </>
        )}

        {/* Fields for Materials */}
        {category === 'Materials' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Tag ID
              </label>
              <input
                type="text"
                value={formData.tagid || ''}
                onChange={(e) =>
                  setFormData({ ...formData, tagid: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                value={formData.name || ''}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="w-full border border-gray-400 rounded-md shadow-sm focus:border-[#015D81] focus:ring focus:ring-[#015D81] px-3 py-2"
              />
            </div>
          </>
        )}

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-600 hover:bg-gray-700 text-white font-medium px-4 py-2 rounded-md transition duration-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-[#015D81] hover:bg-[#014460] text-white font-medium px-4 py-2 rounded-md transition duration-300"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const AddForm = ({ onSave, onClose, category }) => {
  const [formData, setFormData] = useState(
    category === 'Route Transfer'
      ? { tagname: '', name: '', current_idx: '', route: '' } // Include 'tagname' for Route Transfer
      : category === 'Materials'
        ? { tagid: '', tagname: '' } // Include 'tagid' and 'tagname' for Materials
        : { tagname: '', home: '', destination: '' } // Include 'tagname' for Simple Transfer
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    if (!formData.tagname) {
      console.error('Tag name (ID) is required.');
      return;
    }
    if (category === 'Materials' && (!formData.tagid || !formData.tagname)) {
      console.error('Tag ID and Tag Name are required.');
      return;
    }

    if (
      category === 'Simple Transfer' &&
      (!formData.home || !formData.destination)
    ) {
      console.error('Home and Destination are required.');
      return;
    }

    if (
      category === 'Route Transfer' &&
      (!formData.name || !formData.current_idx || !formData.route)
    ) {
      console.error('Name, Current Index, and Route are required.');
      return;
    }

    onSave(formData); // Pass the new tag data to the save handler
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-lg space-y-4"
      >
        {/* Common Field for Tag Name/ID */}
        {category != 'Materials' && (
          <div>
            <label className="block text-sm font-bold mb-1">
              Tag Name / ID
            </label>
            <input
              type="text"
              value={formData.tagname}
              onChange={(e) =>
                setFormData({ ...formData, tagid: e.target.value })
              }
              className="w-full border px-3 py-2 rounded"
              placeholder="Enter tag name or ID"
            />
          </div>
        )}
        {category == 'Materials' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Tag ID</label>
              <input
                type="text"
                value={formData.tagid}
                onChange={(e) =>
                  setFormData({ ...formData, tagid: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter tag name or ID"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Tag Name</label>
              <input
                type="text"
                value={formData.tagname}
                onChange={(e) =>
                  setFormData({ ...formData, tagname: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter tag name or ID"
              />
            </div>
          </>
        )}

        {/* Conditional Fields for Route Transfer */}
        {category === 'Route Transfer' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Job Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter job name"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">
                Current Index
              </label>
              <input
                type="number"
                value={formData.current_idx}
                onChange={(e) =>
                  setFormData({ ...formData, current_idx: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter current index"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Route</label>
              <input
                type="text"
                value={formData.route}
                onChange={(e) =>
                  setFormData({ ...formData, route: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter stations as comma-separated values"
              />
            </div>
          </>
        )}

        {/* Fields for Simple Transfer */}
        {category === 'Simple Transfer' && (
          <>
            <div>
              <label className="block text-sm font-bold mb-1">Home</label>
              <input
                type="text"
                value={formData.home}
                onChange={(e) =>
                  setFormData({ ...formData, home: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter home"
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">
                Destination
              </label>
              <input
                type="text"
                value={formData.destination}
                onChange={(e) =>
                  setFormData({ ...formData, destination: e.target.value })
                }
                className="w-full border px-3 py-2 rounded"
                placeholder="Enter destination"
              />
            </div>
          </>
        )}

        {/* Buttons */}
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>

          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Add Tag
          </button>
        </div>
      </form>
    </div>
  );
};

export default Tags;
