import React, { useEffect, useState, useMemo } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '../Context/Context.js';
import SummaryCard from '../charts/SummaryCard.js';
import TaskSummary from '../charts/TaskSummary.js';
import TaskTypeSummary from '../charts/TaskTypeSummary.js';
import TaskByStation from '../charts/TaskByStation.js';
import BarChartBot from '../charts/BarChartBot.js';
import { ButtonGroup, Button } from '@mui/material';
import { styled, width } from '@mui/system';
import SideBar from './SideBar.js';
import axios from 'axios';

import logo1 from '../../utils/logos/logo1.png';
import logo2 from '../../utils/logos/logo2.png';
import logo3 from '../../utils/logos/logo3.png';
import logo4 from '../../utils/logos/logo4.png';
import logo5 from '../../utils/logos/logo5.png';
import logo6 from '../../utils/logos/logo6.png';
import logob1 from '../../utils/logos/logob1.png';
import logob2 from '../../utils/logos/logob2.png';
import logob3 from '../../utils/logos/logob3.png';

const convertUnixToDate = (unixTime) => new Date(unixTime * 1000);

// Styled filter container div to align right and apply Poppins font
const FilterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '2rem',
  padding: '1rem 2rem',
  fontFamily: 'Poppins, sans-serif',
});

// Button styles to keep it minimal and sleek
const FilterButton = styled(Button)(({ active }) => ({
  color: active ? '#fff' : '#000',
  backgroundColor: active ? '#1976d2' : '#f5f5f5',
  textTransform: 'none',
  border: 'none',
  boxShadow: 'none',
  fontFamily: 'Poppins, sans-serif',
  '&:hover': {
    backgroundColor: active ? '#115293' : '#e0e0e0',
  },
}));

const Analytics = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [tasksData, setTasksData] = useState([]); // State to hold the tasks data

  const [botFilter2, setBotFilter2] = useState([]);
  const [filteredTasks2, setFilteredTasks2] = useState([]);
  const [allBots2, setAllBots2] = useState([]);
  const [selectAll2, setSelectAll2] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);

  const [timeFilter, setTimeFilter] = useState('6m'); // Default filter to "Last 24 Hours"
  const [timeFilter2, setTimeFilter2] = useState('6m'); // Default filter to "Last 24 Hours"
  const { theme, setTheme } = useTheme();
  const [zoneNum, setZoneNum] = useState(0);
  const [stations, setStations] = useState([]);
  const [stationNum, setStationNum] = useState(0);
  const [totalTimeDriven, setTotalTimeDriven] = useState(2);
  const [totalDistanceDriven, setTotalDistanceDriven] = useState(0);


  const pathData = window.sessionStorage.getItem('pathData');
  const paths = pathData ? JSON.parse(pathData).paths : [];
  const botData = window.sessionStorage.getItem('botMapping');
  const allBots = pathData ? JSON.parse(botData) : [];

  const fetchAllBots = async () => {
    try {
      const companyName = window.sessionStorage.getItem('companyName');
      if (!companyName) {
        console.error('Company name is not found in session storage.');
        return;
      }

      const response = await axios.post(
        'https://drobot-admin-v2-a2def93839bb.herokuapp.com/getallbots',
        { companyName }
      );

      const data = response.data;
      console.log('Bots data fetched:', data.botData);
      setAllBots2(data.botData);

      // Add all bots to botFilter2 to make them active
      setBotFilter2(data.botData.map((bot) => bot.id));
    } catch (error) {
      console.error('Error fetching the bots data:', error);
      setAllBots2([]);
    }
  };

  useEffect(() => {
    fetchAllBots();
  }, []);

  useEffect(() => {
    // Fetch `zoneData` from sessionStorage
    const storedStationData = window.sessionStorage.getItem('stationData');

    if (storedStationData) {
      try {
        const parsedData = JSON.parse(storedStationData); // Parse JSON string
        setStationNum(parsedData.length); // Get the number of zones
        setStations(parsedData.zones); // Set the zones data to state
      } catch (error) {
        console.error('Error parsing stations from sessionStorage:', error);
      }
    }
  }, []);

  useEffect(() => {
    setZoneNum(
      JSON.parse(window.sessionStorage.getItem('zoneData')).zones.length
    );

    const setCompanyData = async () => {
      try {
        const companyName = window.sessionStorage.getItem('companyName');
        const response = await axios.post(
          'https://drobot-admin-v2-a2def93839bb.herokuapp.com/getcompletedtasks',
          // "https://drobot-admin-v2-a2def93839bb.herokuapp.com/zzz",
          { company: companyName }
        );

        const data = response.data;
        console.log(data);

        // Ensure tasksData is always an array
        setTasksData(data.completedTasksData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the tasks data', error);
        setTasksData([]); // Set tasksData to an empty array on error
        setLoading(false);
      }
    };

    // Call the function to fetch data on component mount
    setAllBots2();
    setCompanyData();
  }, []);
  // Empty dependency array ensures this runs only once when the component mounts
  useEffect(() => {
    const now = new Date();
    let filteredTasksList = [];
  
    const isValidTask = (task) => {
      const startTime = parseInt(task.task_log?.task_start_time, 10);
      const endTime = parseInt(task.task_log?.task_end_time, 10);
  
      return (
        !isNaN(startTime) &&
        !isNaN(endTime) &&
        task.task_log?.task_start_time !== '' &&
        task.task_log?.task_end_time !== '' &&
        endTime !== 0 &&
        startTime !== 0 &&
        endTime > startTime &&
        Math.abs(endTime - startTime) < 43200
      );
    };
  
    switch (timeFilter) {
      case '24h':
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            isValidTask(task) &&
            now - convertUnixToDate(taskEndTime) <= 24 * 60 * 60 * 1000
          );
        });
        break;
      case '1w':
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            isValidTask(task) &&
            now - convertUnixToDate(taskEndTime) <= 7 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case '1m':
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            isValidTask(task) &&
            now - convertUnixToDate(taskEndTime) <= 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case '6m':
        filteredTasksList = tasksData.filter((task) => {
          const taskEndTime = task.task_log?.task_end_time;
          return (
            taskEndTime &&
            isValidTask(task) &&
            now - convertUnixToDate(taskEndTime) <= 6 * 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case 'all':
      default:
        filteredTasksList = tasksData.filter((task) => isValidTask(task));
    }
  
    setFilteredTasks(filteredTasksList);
  
    let totalTimeDriven = 0,
      totalDistanceDriven = 0;
  
    filteredTasksList.forEach((task) => {
      let startTime = 0,
        endTime = 0;
  
      if (task.task_log?.task_start_time !== '') {
        startTime = parseInt(task.task_log?.task_start_time, 10);
      }
      if (task.task_log?.task_end_time !== '') {
        endTime = parseInt(task.task_log?.task_end_time, 10);
      }
  
      if (startTime && endTime && endTime > startTime) {
        totalTimeDriven += Math.abs(endTime - startTime);
      }
  
      if (task.task_detail?.task_station?.length) {
        totalDistanceDriven += parseFloat(task.task_detail.task_station.length);
      }
    });
  
    setTotalTimeDriven(totalTimeDriven);
    setTotalDistanceDriven(totalDistanceDriven);
  
  }, [tasksData, timeFilter]);
  // Re-run when tasksData or timeFilter changes

  useEffect(() => {
    const now2 = new Date();
    let filteredTasksList2 = [];

    switch (timeFilter2) {
      case '24h':
        filteredTasksList2 = tasksData.filter((task2) => {
          const taskEndTime2 = task2.task_log?.task_end_time;
          return (
            taskEndTime2 &&
            now2 - convertUnixToDate(taskEndTime2) <= 24 * 60 * 60 * 1000
          );
        });
        break;
      case '1w':
        filteredTasksList2 = tasksData.filter((task2) => {
          const taskEndTime2 = task2.task_log?.task_end_time;
          return (
            taskEndTime2 &&
            now2 - convertUnixToDate(taskEndTime2) <= 7 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case '1m':
        filteredTasksList2 = tasksData.filter((task2) => {
          const taskEndTime2 = task2.task_log?.task_end_time;
          return (
            taskEndTime2 &&
            now2 - convertUnixToDate(taskEndTime2) <= 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case '6m':
        filteredTasksList2 = tasksData.filter((task2) => {
          const taskEndTime2 = task2.task_log?.task_end_time;
          return (
            taskEndTime2 &&
            now2 - convertUnixToDate(taskEndTime2) <=
              6 * 30 * 24 * 60 * 60 * 1000
          );
        });
        break;
      case 'all':
      default:
        filteredTasksList2 = tasksData;
    }

    console.log(filteredTasksList2);
    filteredTasksList2 = filteredTasksList2.filter((task2) =>
      botFilter2.includes(task2.task_log.assigned_bot)
    );

    console.log(filteredTasksList2);

    setFilteredTasks2(filteredTasksList2);
  }, [tasksData, timeFilter2, botFilter2]);

  // Handle filter button click
  const handleFilterClick = (filter) => {
    setTimeFilter(filter);
  };
  const handleFilterClick2 = (filter) => {
    setTimeFilter2(filter);
  };

  const botCardData = useMemo(() => {
    return [
      {
        title: 'Completed Tasks',
        subtitle: 'Subtitle goes here',
        value: tasksData.length,
        icon: (
          <img
            src={logob1}
            alt="Active Bots"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: '#9fd575',
      },
      {
        title: 'Working Hours',
        subtitle: 'Subtitle goes here',
        value: tasksData.reduce((totalTime, task) => {
          let startTime = 0.0,
            endTime = 0.0;

          if (task.task_log.task_start_time !== '') {
            startTime = parseInt(task.task_log.task_start_time, 10);
          }
          if (task.task_log.task_end_time !== '') {
            endTime = parseInt(task.task_log.task_end_time, 10);
          }

          if (
            !isNaN(startTime) &&
            !isNaN(endTime) &&
            task.task_log.task_start_time !== '' &&
            task.task_log.task_end_time !== '' &&
            endTime !== 0 &&
            startTime !== 0 &&
            endTime > startTime &&
            Math.abs(endTime - startTime) < 43200
          ) {
            totalTime += Math.abs(parseFloat(endTime - startTime) / 3600);
          }

            totalTime = parseFloat(totalTime.toFixed(3));

          return totalTime;
        }, 0.0), // Initial value of totalTime is 0
        icon: (
          <img src={logob2} alt="Paths" style={{ width: 20, height: 20 }} />
        ),
        iconColor: '#d9b7f3',
      },
      {
        title: 'Total Miles Driven',
        subtitle: 'Subtitle goes here',
        value: tasksData.reduce((totalDistanceDriven, task) => {
          if (task.task_detail?.task_station?.length) {
            totalDistanceDriven += parseFloat(
              task.task_detail.task_station.length
            );
          }

          return totalDistanceDriven;
        }, 0), // Initial value of totalTime is 0,
        icon: (
          <img
            src={logob3}
            alt="People Helped"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: '#677ce9',
      },
    ];
  }, [tasksData]);

  const cardData = useMemo(() => {
    return [
      {
        title: 'Active Bots',
        subtitle: 'Number of unique active bots',
        value: allBots2.length,
        icon: (
          <img
            src={logo1}
            alt="Active Bots"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: '#9fd575',
      },
      {
        title: 'Paths',
        subtitle: 'Total paths completed',
        value: paths.length,
        icon: <img src={logo2} alt="Paths" style={{ width: 20, height: 20 }} />,
        iconColor: '#d096f4',
      },
      {
        title: 'People Helped',
        subtitle: 'Tasks involving people',
        value: new Set(tasksData.map((task) => task.task_log.created_user))
          .size,
        icon: (
          <img
            src={logo3}
            alt="People Helped"
            style={{ width: 20, height: 20 }}
          />
        ),
        iconColor: '#f2c657',
      },
      {
        title: 'Forklifts',
        subtitle: 'Tasks with forklifts',
        value: tasksData.filter((task) => task.equipment === 'forklift').length,
        icon: (
          <img src={logo4} alt="Forklifts" style={{ width: 20, height: 20 }} />
        ),
        iconColor: '#f18f70',
      },
      {
        title: 'Stations',
        subtitle: 'Total stations',
        value: stationNum,
        icon: (
          <img src={logo5} alt="Stations" style={{ width: 20, height: 20 }} />
        ),
        iconColor: '#3dcfcf',
      },
      {
        title: 'Zones',
        subtitle: 'Tasks in zones',
        value: zoneNum,
        icon: <img src={logo6} alt="Zones" style={{ width: 20, height: 20 }} />,
        iconColor: '#f3b7de',
      },
    ];
  }, [tasksData]);

  // If the data is still loading, show a loader
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <p>Loading analytics data...</p>
      </div>
    );
  }

  // If tasksData is still not available, return null (optional safeguard)
  if (!tasksData) {
    return null;
  }

  const handleBotFilterClick2 = (bot2) => {
    if (bot2 === 'selectAll') {
      if (selectAll2) {
        setBotFilter2(allBots2); // Deselect all bots
      } else {
        setBotFilter2(allBots2); // Select all bots
      }
      setSelectAll2(!selectAll2);
    } else {
      setBotFilter2(
        (prev) =>
          prev.includes(bot2)
            ? prev.filter((b) => b !== bot2) // Remove bot if already selected
            : [...prev, bot2] // Add bot if not selected
      );
      setSelectAll2(false); // Uncheck "Select All" if individual bot is toggled
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <SideBar />
      <div
        style={{
          display: 'flex',
          padding: '0rem',
          backgroundColor: theme === 'light' ? '#f5f5f5' : '#a3cad9',
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              backgroundColor: theme === 'light' ? '#DBF1FAF2' : '#0B2D3B',
              color: theme === 'light' ? '#0B2D3B' : '#fff',
              padding: '2rem 2rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between', // Add this to push items to the edges
              alignItems: 'center', // Vertically center the items
            }}
          >
            <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>
              Analytics
            </span>
            <button
              className="text-black font-semibold bg-white px-2 py-2 rounded-lg hover:bg-[grey] hover:text-white hover:scale-105 transition-colors duration-300"
              onClick={() => (window.location.href = '/home')}
            >
              {`←  Back to Home`}
            </button>
          </div>

          <div style={{ padding: '0rem 2rem' }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: '1rem',
                marginTop: '1rem',
                marginBottom: '0.5rem',
              }}
            >
              Summary
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
              {cardData.map((card, index) => (
                <div
                  key={index}
                  style={{
                    flex: '1 1 calc(33.333% - 24px)', // 3 columns for larger screens (md/lg)
                    maxWidth: 'calc(33.333% - 24px)', // Enforce max width per card
                    minWidth: '300px', // Minimum width for smaller screens
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <SummaryCard
                    title={card.title}
                    // subtitle={card.subtitle}
                    value={card.value}
                    icon={card.icon}
                    iconColor={card.iconColor}
                  />
                </div>
              ))}
            </div>
          </div>

          <FilterContainer>
            <ButtonGroup variant="contained">
              <FilterButton
                onClick={() => handleFilterClick('all')}
                active={timeFilter === 'all'}
              >
                All Time
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick('24h')}
                active={timeFilter === '24h'}
              >
                Last 24 Hours
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick('1w')}
                active={timeFilter === '1w'}
              >
                Last 1 Week
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick('1m')}
                active={timeFilter === '1m'}
              >
                Last 1 Month
              </FilterButton>
              <FilterButton
                onClick={() => handleFilterClick('6m')}
                active={timeFilter === '6m'}
              >
                Last 6 Months
              </FilterButton>
            </ButtonGroup>
          </FilterContainer>

          <div
            style={{
              ...appContainerStyle,
              backgroundColor: theme === 'light' ? '#f5f5f5' : '#a3cad9',
            }}
          >
            <div style={cardStyle}>
              <TaskSummary filteredTasks={filteredTasks} />
            </div>
            <div style={cardStyle}>
              <TaskTypeSummary filteredTasks={filteredTasks} />
            </div>
            <div style={cardStyle}>
              <TaskByStation filteredTasks={filteredTasks} />
            </div>
          </div>

          {/* <div style={{ padding: "1rem 2rem" }}>
          <div style={{ fontWeight: 600, fontSize: "1rem", marginTop: "1rem" }}>
            Filtered Tasks Count: {filteredTasks.length}
          </div>
        </div> */}

          <div style={{ padding: '0rem 2rem' }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: '1rem',
                marginTop: '1rem',
                marginBottom: '0.5rem',
              }}
            >
              Bots
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
              {botCardData.map((card, index) => (
                <div
                  key={index}
                  style={{
                    flex: '1 1 calc(33.333% - 24px)', // 3 columns for larger screens (md/lg)
                    maxWidth: 'calc(33.333% - 24px)', // Enforce max width per card
                    minWidth: '300px', // Minimum width for smaller screens
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <SummaryCard
                    title={card.title}
                    // subtitle={card.subtitle}
                    value={card.value}
                    icon={card.icon}
                    iconColor={card.iconColor}
                  />
                </div>
              ))}
            </div>

            <FilterContainer>
              {/* Time Filter */}
              <div>
                <ButtonGroup variant="contained">
                  <FilterButton
                    onClick={() => handleFilterClick2('all')}
                    active={timeFilter2 === 'all'}
                  >
                    All Time
                  </FilterButton>
                  <FilterButton
                    onClick={() => handleFilterClick2('24h')}
                    active={timeFilter2 === '24h'}
                  >
                    Last 24 Hours
                  </FilterButton>
                  <FilterButton
                    onClick={() => handleFilterClick2('1w')}
                    active={timeFilter2 === '1w'}
                  >
                    Last 1 Week
                  </FilterButton>
                  <FilterButton
                    onClick={() => handleFilterClick2('1m')}
                    active={timeFilter2 === '1m'}
                  >
                    Last 1 Month
                  </FilterButton>
                  <FilterButton
                    onClick={() => handleFilterClick2('6m')}
                    active={timeFilter2 === '6m'}
                  >
                    Last 6 Months
                  </FilterButton>
                </ButtonGroup>
              </div>

              {/* Bot Filter */}
              <div style={{ display: 'flex', alignItems: 'center', padding: '0 16px 0 32px', fontWeight: 600, fontSize: '1rem' }}>Select Bots:</div>
              {/* <span style={{ width: '24px' }}>   </span> */}
              <div>
                <ButtonGroup variant="contained">
                  {allBots2.map((bot2, index2) => (
                    <FilterButton
                      key={index2}
                      onClick={() => handleBotFilterClick2(bot2.id)} // Pass bot2.id for filtering
                      active={botFilter2.includes(bot2.id)} // Check against the bot ID
                    >
                      {bot2.id} {/* Render bot2.id as the button text */}
                    </FilterButton>
                  ))}
                </ButtonGroup>
              </div>
            </FilterContainer>

            <div
              style={{
                ...appContainerStyle,
                backgroundColor: theme === 'light' ? '#f5f5f5' : '#a3cad9',
              }}
            >
              <div style={cardStyle}>
                <BarChartBot filteredTasks={filteredTasks2} />
                {/* <TaskSummary filteredTasks={filteredTasks2} /> */}
              </div>
              {/* <div style={cardStyle}>
                <TaskSummary filteredTasks={filteredTasks2} />
              </div> */}
            </div>
            {/* <div>
              <h2>Filtered Tasks : {filteredTasks2.length}</h2>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const appContainerStyle = {
  display: 'flex',
  gap: '16px',
  padding: '16px',
  backgroundColor: '#fff',
};

const cardStyle = {
  flex: 1,
  background: 'white',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: '24px',
};

export default Analytics;
