import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';

const IconWrapper = styled('div')(({ bgcolor }) => ({
  backgroundColor: bgcolor || '#ccc',
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  marginRight: '15px',
}));

const SummaryCard = ({ icon, title, subtitle, value, iconColor }) => {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
        width: '24vw',
      }}
    >
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconWrapper bgcolor={iconColor}>{icon}</IconWrapper>
          <div>
            <Typography
              variant="subtitle1"
              fontWeight="600"
              sx={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {subtitle}
            </Typography>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Typography
              variant="h4"
              fontWeight="Semibold"
              sx={{ fontFamily: 'Poppins, sans-serif' }}
            >
              {value}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
