import React, { createContext, useContext, useState, useEffect } from 'react';

const Theme = createContext();
const PathDrawContext = createContext();
const PathAIDrawContext = createContext();
const PathContext = createContext();
const ZoneContext = createContext();
const pathData = window.sessionStorage.getItem('pathData');
const paths = pathData ? JSON.parse(pathData).paths : [];
const zoneData = window.sessionStorage.getItem('zoneData');
const zones = zoneData ? JSON.parse(zoneData).zones : [];
const stationData = window.sessionStorage.getItem('stationData');
const stations = stationData ? JSON.parse(stationData) : [];
function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light');
  const [ToRecord, setToRecord] = useState(false);
  const [selectedBot, setSelectedBot] = useState(null);
  useEffect(() => {
    const storedTags = window.sessionStorage.getItem('tags');
    const botMapping = JSON.parse(window.sessionStorage.getItem('botMapping'));

    if (storedTags && botMapping) {
      const parsedTags = JSON.parse(storedTags);
      const filteredBots = Object.keys(botMapping)
        .filter((tagId) => parsedTags.hasOwnProperty(tagId))
        .map((tagId) => botMapping[tagId]);

      setSelectedBot(filteredBots); // Set selected bots only when tags exist
    }
  }, []);
  const [selectedPath, setSelectedPath] = useState(paths);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [selectedStation, setSelectedStation] = useState(stations);
  const [pathRecord, setPathRecord] = useState({ x: [], y: [] });
  const [showParking, setShowParking] = useState(false);
  const [selectedParking, setSelectedParking] = useState([]);
  const [showCartParking, setShowCartParking] = useState(false);
  const [selectedCartParking, setSelectedCartParking] = useState([]);
  const [showStation, setShowStation] = useState(true);
  const [showBots, setShowBots] = useState(true);
  const [showAprilTags, setShowAprilTags] = useState(false);  
  const [showPeople, setShowPeople] = useState(false);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showZone, setShowZone] = useState(false);
  const [showpath, setShowpath] = useState(true);
  const [canvasWidth, setCanvasWidth] = useState(1);
  const [AIpath, setAIpath] = useState(false);
  const [tagName, settagName] = useState();
  const [traceTagName, settraceTagName] = useState();
  const [traceStation, setTraceStation] = useState();
  const [AprilTags, setAprilTags] = useState(false);
  const [DeJitterParam, setDeJitterParam] = useState(25);
  const [lastDeJitterValue, setLastDeJitterValue] = useState(25);
  const [AprilTagsCoordinates, setAprilTagsCoordinates] = useState({
    x: null,
    y: null,
    yaw: 0,
  });
  const [stationTraceArray, setStationTraceArray] = useState({
    x: [],
    y: [],
  });
  const [stationTraceCoordinate, setStationTraceCoordinate] = useState({
    x: null,
    y: null,
  });
  const [AIpathArray, setAIpathArray] = useState({ x: [], y: [] });
  const [stationRecord, setStationRecord] = useState(false);
  const [AIPathSensitivity, setAIPathSensitivity] = useState(1);
  const [tracePath, setTracePath] = useState(false);
  const [hoveredPath, setHoveredPath] = useState(null);
  const [stationMarkedCoordinate, setStationMarkedCoordinate] = useState({
    x: null,
    y: null,
  });
  const [parkingSpaceCoordinates, setParkingSpaceCoordinates] = useState({
    station: null,
    x: null,
    y: null,
    yaw: 0,
  });
  const [cartParkingSpaceCoordinates, setCartParkingSpaceCoordinates] =
    useState({
      station: null,
      state: null,
      P: {
        center: {
          x: null,
          y: null,
        },
        x: [null, null, null, null],
        y: [null, null, null, null],
      },
      P_Prime: {
        x: null,
        y: null,
      },
      P1: {
        x: null,
        y: null,
      },
      P2: {
        x: null,
        y: null,
      },
      yaw: null,
    });
  const [startParkingSpaceRecord, setStartParkingSpaceRecord] = useState(false);
  const [selectedParkingSpace, setSelectedParkingSpace] = useState([]);
  const [startCartParkingSpaceRecord, setStartCartParkingSpaceRecord] =
    useState(false);
  const [botTraceArray, setBotTraceArray] = useState({
    x: [],
    y: [],
  });
  const [isDejitterActive, setIsDejitterActive] = useState(false);
  const [jitterbotCoordinates, setjitterbotCoordinates] = useState({
    x: [],
    y: [],
  });
  const [cameraOffset, setCameraOffset] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [ZonePointArray, setZonePointArray] = useState({
    x: [],
    y: [],
  });
  const [CreateZone, setCreateZone] = useState(false);
  useEffect(() => {
    setSelectedPath(paths);
  }, [paths]);
  return (
    <Theme.Provider value={{ theme, setTheme }}>
      <PathDrawContext.Provider value={{ ToRecord, setToRecord }}>
        <PathAIDrawContext.Provider
          value={{ AIpath, setAIpath, AIpathArray, setAIpathArray }}
        >
          <PathContext.Provider
            value={{
              pathRecord,
              setPathRecord,
              showStation,
              setShowStation,
              showpath,
              setShowpath,
              hoveredPath,
              setHoveredPath,
              canvasWidth,
              setCanvasWidth,
              cameraOffset,
              setCameraOffset,
              selectedBot,
              setSelectedBot,
              selectedPath,
              setSelectedPath,
              selectedZone,
              setSelectedZone,
              selectedPeople,
              setSelectedPeople,
              showParking,
              setShowParking,
              selectedParking,
              setSelectedParking,
              showBots,
              setShowBots,
              showAprilTags,
              setShowAprilTags,
              showCartParking,
              setShowCartParking,
              selectedCartParking,
              setSelectedCartParking,
              showZone,
              setShowZone,
              showMaterials,
              setShowMaterials,
              showPeople,
              setShowPeople,
              selectedStation,
              setSelectedStation,
              stationRecord,
              setStationRecord,
              stationMarkedCoordinate,
              setStationMarkedCoordinate,
              AIPathSensitivity,
              setAIPathSensitivity,
              tracePath,
              setTracePath,
              tagName,
              settagName,
              botTraceArray,
              setBotTraceArray,
              jitterbotCoordinates,
              setjitterbotCoordinates,
              parkingSpaceCoordinates,
              setParkingSpaceCoordinates,
              startParkingSpaceRecord,
              setStartParkingSpaceRecord,
              startCartParkingSpaceRecord,
              setStartCartParkingSpaceRecord,
              cartParkingSpaceCoordinates,
              setCartParkingSpaceCoordinates,
              selectedParkingSpace,
              setSelectedParkingSpace,
              traceTagName,
              settraceTagName,
              traceStation,
              setTraceStation,
              stationTraceArray,
              setStationTraceArray,
              stationTraceCoordinate,
              setStationTraceCoordinate,
              AprilTags,
              setAprilTags,
              AprilTagsCoordinates, 
              setAprilTagsCoordinates,
              DeJitterParam, setDeJitterParam,
              isDejitterActive, setIsDejitterActive,
              lastDeJitterValue, setLastDeJitterValue
            }}
          >
            <ZoneContext.Provider
              value={{
                ZonePointArray,
                setZonePointArray,
                CreateZone,
                setCreateZone,
              }}
            >
              {children}
            </ZoneContext.Provider>
          </PathContext.Provider>
        </PathAIDrawContext.Provider>
      </PathDrawContext.Provider>
    </Theme.Provider>
  );
}

export const useTheme = () => {
  return useContext(Theme);
};

export const usePathDraw = () => {
  return useContext(PathDrawContext);
};
export const useAIPathDraw = () => {
  return useContext(PathAIDrawContext);
};
export const useRecordPath = () => {
  return useContext(PathContext);
};
export const useZone = () => {
  return useContext(ZoneContext);
};

export default ThemeProvider;
